const { connect } = require("react-redux")

const mapStateToProps = ({ auth,userConfig, transferData,receiveData,internalTransferData,digitalWalletData}) => {
    return { profile: auth.profile, user: auth.user,userProfile:userConfig.userProfileInfo,twoFA:userConfig.twoFA,transferData,receiveData,internalTransferData,digitalWalletData}
}
const mapDispatchToProps = dispatch => {
    return { dispatch }
}
const ConnectStateProps = connect(mapStateToProps, mapDispatchToProps);


export default ConnectStateProps;