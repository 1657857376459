import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import Layout from "antd/lib/layout";
import Menu from "antd/lib/menu";
import Dropdown from "antd/lib/dropdown";

import {
  fetchFeatures,
  setSelectedFeatureMenu
} from "../../../reducers/featuresReducer";
import { headerSubscriber } from "../../../utils/pubsub";
import { checkCustomerState } from "../../../utils/service";
import { KEY_URL_MAP } from "./config";
import { setCurrentAction } from "../../../reducers/actionsReducer";
import DefaultUser from "../../../assets/images/defaultuser.jpg";
import { getmemeberInfo } from "../../../reducers/configReduser";
import { connect } from "react-redux";
import Title from "antd/lib/skeleton/Title";
import { getFeaturePermissionsByKey } from "./permissionService";
import Transfer from "../../transfer.component";
const { Sider } = Layout;

class MobileHeaderMenu extends Component {
  render() {
    const {
      onMenuItemClick,
      features: {
        features: { data }
      }
    } = this.props;
    return (
      <>
        <Menu theme="light" mode="vertical" className="header-right">
          <Menu.Item
            className="list-item"
            onClick={this.props.routeToDashboard}
          ></Menu.Item>
          {data?.map((item, indx) =>
            item.menuitemType === "dropdown" ? (
              <>
                <Menu.Item className="mr-16">{item.content}</Menu.Item>

                <Menu>
                  <ul className="pl-0 drpdwn-list">
                    {item?.subMenu?.map((subItem) => (
                      <li onClick={() => onMenuItemClick(subItem.key, subItem)}>
                        <Link>
                          <Title>{subItem.content}</Title>{" "}
                          <span className="icon md rarrow-white" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Menu>
              </>
            ) : (
              item.key === "transfer" && (
                <Menu.Item
                  className="list-item"
                  onClick={() => onMenuItemClick(item.key, item)}
                ></Menu.Item>
              )
            )
          )}
        </Menu>
      </>
    );
  }
}

class HeaderPermissionMenu extends Component {
  state = {
    visbleProfileMenu: false,
    drawerMenu: {
      transfer: false
    }
  };
  componentDidMount() {
   // this.props.getmemeberInfoa()
    this.props.dispatch(
      fetchFeatures(
        this.props.userConfig.appId || "178A3680-3B6F-44AD-9EF2-69EA040C16CC"
      )
    );
    this.menuClickSub = headerSubscriber.subscribe(({ menuitem, menuKey }) =>
      this.onMenuItemClick(menuitem, menuKey)
    );
  }
  componentWillUnmount() {
    this.menuClickSub.unsubscribe();
  }
  onMenuItemClick = async (menuKey, menuItem) => {
    if (
      this.props.userConfig.isKYC &&
      this.props.userConfig.twofactorVerified &&
      checkCustomerState(this.props.userConfig)
    ) {
      if (!this.props.menuItems.featurePermissions[menuItem.key]) {
        getFeaturePermissionsByKey(menuItem.key, (data) => {
          if (data.ok) {
            this.chekPermissions(menuKey, menuItem, data?.data);
          }
        });
      } else {
        this.chekPermissions(
          menuKey,
          menuItem,
          this.props.menuItems.featurePermissions[menuItem.key]
        );
      }
    } else {
      const isKyc = !this.props.userConfig.isKYC;
      if (isKyc) {
        this.props.history.push("/notkyc");
      } else {
        this.showDocRequestError();
      }
    }
  };
  showDocRequestError() {
    if (!checkCustomerState(this.props.userConfig)) {
      this.props.history.push("/sumsub");
    } else if (!this.props.twoFA?.isEnabled) {
      this.props.history.push("/enabletwofactor");
    } else if (this.props?.userConfig?.isDocsRequested) {
      this.props.history.push("/docnotices");
    }
  }
  navigate = (menuKey, menuItem) => {
    if (menuItem.path === "/modal") {
      switch (menuKey) {
        case "transfer":
          this.setState({
            ...this.state,
            drawerMenu: { ...this.state.drawerMenu, transfer: true }
          });

          break;

        default:
          break;
      }
      this.setState({
        ...this.state,
        drawerMenu: { ...this.state.drawerMenu, [menuKey]: true }
      });
     }
    else {
      this.props.history.push(menuItem.path);
    }
  };
  chekPermissions = (menuKey, menuItem, data) => {
    const viewPer = data?.actions.find(
      (item) => item.permissionName == "view" || item.permissionName == "send" || item.permissionName == "receive" || item.permissionName == "transfer" 
      || item.permissionName == "View" || item.permissionName == "Send"

      );
    if (!viewPer?.values) {
      this.props.history.push("/accessdenied");
    } else {
      this.props.dispatch(setSelectedFeatureMenu(menuItem.id));
        this.navigate(menuKey, menuItem);
    }
  };
  closeDrawer = (key) => {
    if (
      this.props.menuItems?.featurePermissions?.[
        KEY_URL_MAP[window.location.pathname]
      ]?.featureId
    ) {
      this.props.dispatch(
        setSelectedFeatureMenu(
          this.props.menuItems?.featurePermissions?.[
            KEY_URL_MAP[window.location.pathname]
          ]?.featureId
        )
      );
    }

    if (this.child) this.child.clearValues();
    let obj = {};
    this.props.fromObjSwap(obj);
    this.props.receiveObjSwap(obj);
    this.props.updateSwapdataobj({
      fromCoin: null,
      receiveCoin: null,
      price: null,
      fromValue: null,
      receiveValue: null,
      errorMessage: null
    });
    this.props.clearSwapfullData();
    if (key === "transfer") {
      this.setState({
        ...this.state,
        drawerMenu: { ...this.state.drawerMenu, transfer: false }
      });
    } else {
      this.setState({
        ...this.state,
        drawerMenu: { ...this.state.drawerMenu, [key]: false }
      });
    }
  };

  render() {
    const userProfileMenu = (
      <Menu>
        <div className="profile-dropdown">
          {this.props.userConfig?.imageURL != null && (
            <img
              src={
                this.props.userConfig?.imageURL
                  ? this.props.userConfig?.imageURL
                  : DefaultUser
              }
              className="user-profile"
              alt={"image"}
            />
          )}
          {this.props.userConfig?.imageURL === null && (
            <img
              src={
                this.props.userConfig?.imageURL
                  ? this.props.userConfig?.imageURL
                  : DefaultUser
              }
              className="user-profile"
              alt={"image"}
            />
          )}
          <p
            className="mb-15 ml-8 profile-value"
            style={{ flexGrow: 12, marginTop: "5px" }}
          >
            {this.props.userConfig?.isBusiness ? (
              this.props.userConfig?.businessName
            ) : (
              <>
                {this.props.userConfig?.firstName}{" "}
                {this.props.userConfig?.lastName}
              </>
            )}
          </p>
        </div>
      </Menu>
    );
    const { collapsed, isShowSider } = this.props;
    const { transfer } = this.state.drawerMenu;
    return (
      <>
        <Menu
          theme="light"
          mode="horizontal"
          className="header-right mobile-headerview d-none"
        >
          <Dropdown
            onVisibleChange={() =>
              this.setState({
                ...this.state,
                visbleProfileMenu: !this.state.visbleProfileMenu
              })
            }
            visible={this.state.visbleProfileMenu}
            onClick={() =>
              this.setState({ ...this.state, visbleProfileMenu: true })
            }
            overlay={userProfileMenu}
            placement="topRight"
            arrow
            overlayClassName="secureDropdown"
            getPopupContainer={() => document.getElementById("area")}
          >
            <Menu.Item key="10" className="ml-16">
              {this.props.userConfig?.imageURL != null && (
                <img
                  src={
                    this.props.userConfig?.imageURL
                      ? this.props.userConfig?.imageURL
                      : DefaultUser
                  }
                  className="user-profile"
                  alt={"image"}
                />
              )}
              {this.props.userConfig?.imageURL === null && (
                <img
                  src={
                    this.props.userConfig?.imageURL
                      ? this.props.userConfig?.imageURL
                      : DefaultUser
                  }
                  className="user-profile"
                  alt={"image"}
                />
              )}
            </Menu.Item>
          </Dropdown>
        </Menu>
        {isShowSider && (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={0}
            className={` ${collapsed ? "" : "sideropen"}`}
          >
            <MobileHeaderMenu
              onMenuItemClick={this.onMenuItemClick}
              routeToDashboard={this.props.routeToDashboard}
              features={this.props.menuItems}
              dispatch={this.props.dispatch}
            />
          </Sider>
        )}
        {transfer && (
          <Transfer
            showDrawer={transfer}
            onClose={() => this.closeDrawer("transfer")}
          />
        )}
      </>
    );
  }
}
const connectStateToProps = ({ userConfig, auth, dashboard, menuItems }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    twoFA: userConfig.twoFA,
    dashboard,
    auth,
    menuItems
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    getmemeberInfoa: (useremail) => {
      dispatch(getmemeberInfo(useremail));
    },
    setAction: (val) => {
      dispatch(setCurrentAction(val));
    },
    dispatch
  };
};
export default withRouter(
  connect(connectStateToProps, connectDispatchToProps)(HeaderPermissionMenu)
);
