import React, { useEffect, useState } from 'react';

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Alert from "antd/lib/alert";
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { setDigitalWalletStep ,summaryDetails} from '../../reducers/digitalWalletReducer'
import {getVerificationFields,getAccountBalance} from './api'
import { isErrorDispaly } from "../../api/apiCalls";
import Loader from "../shared/loader";


const TransferAmount = (props) => {
    const [form] = Form.useForm();
    const { Text } = Typography;
   const enteramtForm = React.useRef(null);
    const [errorMsg, setErrorMsg] = useState();
    const[isVerificationEnable,setIsVerificationEnable] = useState(false);
    const [btnLoading,setBtnLoading] =useState(false);
    const [loading]=useState(false);
    useEffect(() => {
        if (props?.amount) {
            form.setFieldsValue({ toValue: props?.amount })
        }
        verificationCheck()
    },[])
   const verificationCheck = async () => {
    setIsVerificationEnable(true);
        const verfResponse = await getVerificationFields();
        let minVerifications = 0;
        if (verfResponse.ok) {
            for (let verifMethod in verfResponse.data) {
                if (["isEmailVerification", "isPhoneVerified", "twoFactorEnabled", "isLiveVerification"].includes(verifMethod) && verfResponse.data[verifMethod] === true) {
                    minVerifications = minVerifications + 1;
                }
            }
            if (minVerifications >= 1) {
                setIsVerificationEnable(true);
            } else {
                setIsVerificationEnable(false);
            }
        } else {    
            setErrorMsg(isErrorDispaly(verfResponse))
        }
    }
   
    const summaryDetailsData = async() => {
        setErrorMsg(null)
        setBtnLoading(true)
        let _amount = enteramtForm.current?.getFieldsValue().toValue;
       let _formAmt = _amount.replace(/,/g, '')
       if(_formAmt=="."){
        setErrorMsg("Invalid amount")
        setBtnLoading(false)
       }else{
       let obj={
        "accountId":props?.selectedBank?.id || props?.currencyObj?.id,
        "PayeeId":null,
        "amount":parseFloat(_formAmt),
        "reasonOfTransfer":"transfer",
        "documents":null
    }
    if(parseFloat(_formAmt)==0){
        setErrorMsg("Amount must be greater than zero.")
        setBtnLoading(false)
    }else{
    let response = await getAccountBalance(obj)
    if(response.ok){
        setBtnLoading(false)
        if (response.data?.requestedAmount === 0) {
            setErrorMsg("Amount must be greater than zero.")
             setBtnLoading(false)
            return;
        }
        else if (response.data?.requestedAmount > parseFloat(props?.currencyObj?.availableBalance)) {
            setErrorMsg(`Insufficient funds.`);
            setBtnLoading(false)
            return;
        }
        else {

            props?.setAmount(response.data?.requestedAmount);
            props?.summaryDetails(response.data)
            props?.changeStep("sendSummary")
         
        }
        
    }else{
        setErrorMsg(isErrorDispaly(response));
         setBtnLoading(false)
    }
}
}
        
    }
   
    return (
        <>
            {errorMsg != null && <Alert
                description={errorMsg}
                type="error"
                showIcon
                closable={false}
            />}
             {loading && <Loader/>}
             {!loading &&
            <Form
                form={form}
                autoComplete="off"
                onFinish={() => summaryDetailsData()}
                ref={enteramtForm}
            >
                  {!isVerificationEnable &&
                            <Alert
                                message="Verification alert !"
                                description={<Text>Without verifications you can't send. Please select verifications from <a onClick={() => {
                                    localStorage.setItem("myurl",`${process.env.REACT_App_EXCHANGE_URL}/userprofile/2`)
                                    window.open(`${process.env.REACT_App_EXCHANGE_URL}/userprofile/2`,"_self")
                                }}>security section</a></Text>}
                                type="warning"
                                showIcon
                                closable={false}
                            />
                        }
                           {(isVerificationEnable && props.permission?.Transfer) &&
                            
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                            className="custom-forminput custom-label fund-transfer-input send-fiat-input"
                            name="toValue"
                            label="Amount"
                            rules={[
                                {
                                    whitespace: true,
                                    required: true,
                                    message: "Is required",
                                    },
                                  
                                ]}
                        >
                            <NumberFormat
                                className="cust-input cust-select-arrow tfunds-inputgroup"
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Enter Amount"
                                decimalScale={2}
                                allowNegative={false}
                                onValueChange={() => {
                                    setErrorMsg(null) }
                                 }
                                maxlength="13"
                                addonBefore={
                                    <span className="btn-space">

                                        {props?.selectedBank?.currency }
                                         ({props?.selectedBank?.availableBalance == null ? 0 :props?.selectedBank?.availableBalance.toLocaleString()})
                                    </span>
                                }
                                
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                        <div className="text-right mt-36">
                            <Form.Item className="mb-0 mt-16 btn-unblock">
                                <Button
                                    htmlType="submit"
                                    size="large"
                                    block
                                    className="pop-btn px-24"
                                    loading={btnLoading}
                                >
                                    Continue
                                </Button>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                }
            </Form>
}
        </>
    )
}
const connectStateToProps = ({ userConfig, digitalWalletData }) => {
    return {
        userConfig: userConfig.userProfileInfo, digitalWalletData

    };
};
const connectDispatchToProps = dispatch => {
    return {
        changeStep: (stepcode) => {
            dispatch(setDigitalWalletStep(stepcode))
        },
        summaryDetails:(data)=>{
            dispatch(summaryDetails(data))
        },
        dispatch
    }

}
export default connect(connectStateToProps, connectDispatchToProps)(TransferAmount)
