const SET_DETAILS_STEP = "setDetailsStep";
const SET_ADDDETAILS_STEP = "setAddDetailsStep";


  const setDetailsStep = (payload) => {
    return {
        type: SET_DETAILS_STEP,
        payload
    }
    }
    const setAddDetailsStep = (payload) => {
        return {
            type: SET_ADDDETAILS_STEP,
            payload
        }
        }

  let initialState={
    data:[],
    addData:[]

  }
  
  
  const detailsReducer = (state = initialState,action) =>{
    switch (action.type) {
        case SET_DETAILS_STEP:
            return { ...state, data: action.payload };
            case SET_ADDDETAILS_STEP:
            return { ...state, data: action.payload };
    default:
        return state;
  }

    }
    export default detailsReducer;
    export {setDetailsStep,setAddDetailsStep}
