const SET_DIGITALWALLET_STEP = "setDigitalWalletStep";
const SET_AMOUNT_VALUE = "setAmountValue";
const SET_CURRENCY = "setCurrency;"
const REFRESH_DASHBOARD = "refreshDashboard;"
const SUMMARYDETAILS="summaryDetails"

const setDigitalWalletStep = (payload) => {
  return {
    type: SET_DIGITALWALLET_STEP,
    payload
  }
}
const setAmountValue = (payload) => {
  return {
    type: SET_AMOUNT_VALUE,
    payload
  }
}
const setCurrency = (payload) => {
  return {
    type: SET_CURRENCY,
    payload
  }
}
const refreshDashboard = (payload) => {
  return {
    type: REFRESH_DASHBOARD,
    payload
  }
}
const summaryDetails = (payload) =>{
  return {
    type: SUMMARYDETAILS,
    payload
  }
}
let initialState = {
  stepcode: "transferAmount",
  stepTitles: {
    transferAmount: "Transfers Funds",
    sendSummary: "Review details of transfer",
    sendSuccess: "Sucess Details of Suissebase Wallet",
    confimationSlip: "Confirmation Slip"
  },
  amount: "",
  currencyObj: {},
  isDashboardCall: false,
  summaryData : []
}


const digitalWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DIGITALWALLET_STEP:
      return { ...state, stepcode: action.payload };
    case SET_AMOUNT_VALUE:
      return { ...state, amount: action.payload };
    case SET_CURRENCY:
      return { ...state, currencyObj: action.payload };
    case REFRESH_DASHBOARD:
      return { ...state, isDashboardCall: action.payload };
    case SUMMARYDETAILS:
      return { ...state, summaryData: action.payload };
    default:
      return state;
  }
}
export default digitalWalletReducer;
export { setDigitalWalletStep, setAmountValue, setCurrency, refreshDashboard,summaryDetails }
