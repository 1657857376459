import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Typography,
  Input,
  Tabs,
  Button,
  Alert,
  Spin,
  Image,
  Select
} from "antd";
import {
  getRecipientData,
  saveTransferData,
  getIBANData,isErrorDispaly,
  getCountryStateLu
} from "../../../api/apiCalls";
import { validateContentRule,validateFeild } from "../../../utils/custom.validator";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import alertIcon from "../../../assets/images/pending.png";
import success from '../../../assets/images/success.svg'
const { Paragraph, Title } = Typography;

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const MyselfNewTransfer = ({
  currency,
  isBusiness,
  onTheGoObj,
  selectedAccountId,
  ...props
}) => {
  const [form] = Form.useForm();
  const [addressOptions, setAddressOptions] = useState({
    addressType: "myself",
    transferType: currency === "EUR" ? "sepa" : currency === "GBP" ?'domestic' : "swift",
    domesticType: "sepa",
    tabType: currency === "EUR" ? "sepa" :'domestic'
  });
  const [bankDetails, setbankDetails] = useState({});
  const [saveTransferObj, setsaveObj] = useState({
    id: "00000000-0000-0000-0000-000000000000",
    customerId: props.userConfig.id,
    favouriteName: "",
    firstName: "",
    lastName: "",
    beneficiaryName: "",
    line1: "",
    line2: "",
    line3: "",
    transferType: "",
    addressType: "",
    isAgree: true,
    info: "",
    isBankContact: true,
    relation: "",
    reasonOfTransfer: "",
    amount: 0,
    payeeAccountModels: [
      {
        id: "00000000-0000-0000-0000-000000000000",
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        currencyType: "",
        walletCode: "",
        accountNumber: "",
        swiftRouteBICNumber: "",
        bankName: "",
        userCreated: props?.userConfig?.userName,
        iban: "",
        bic: "",
        bankBranch: "",
        abaRoutingCode: "",
        documents: null
      }
    ]
  });
  const [createTransfer, setcreateTransfer] = useState({
    favouriteName: "",
    accountNumber: "",
    swiftRouteBICNumber: "",
    bankName: "",
    iban: "",
    abaRoutingCode: "",
    line1: "",
    line2: ""
  });
  const [recipientDetails, setRecipientDetails] = useState({});
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isLoading, setLoader] = useState(true);
  const [ibanLoading, setIbanLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const useDivRef = React.useRef(null);
  const [validIban, setValidIban] = useState(false);
  const [showDeclartion, setShowDeclartion] = useState(false);
  const [isBankDetailsLoading, setIsBankDetailsLoading] = useState(false);
  const [isShowValid, setIsShowValid] = useState(false);
  const [enteredIbanData, setEnteredIbanData] = useState(null);
  const [isShowBankDetails, setIsShowBankDetails] = useState(false);
  const [country, setCountry] = useState([])
  useEffect(() => {
    setBtnLoading(false)
    getRecipientDetails();
    if(currency === "GBP")
     getCountry();
  }, []);
  const getRecipientDetails = async () => {
    setLoader(true);
    const response = await getRecipientData(
      props.selectedAddress?.id || "00000000-0000-0000-0000-000000000000" ,
      isBusiness ? "OwnBusiness" : "MySelf"  
    );
    if (response.ok) {
      if (props.selectedAddress?.id) {
        setLoader(false);
        setRecipientDetails(response.data);
        setsaveObj(response.data);
        setbankDetails(response.data.payeeAccountModels[0]);
        setcreateTransfer(response.data);
        let obj = Object.assign({}, response.data.payeeAccountModels[0]);
        form.setFieldsValue({
          favouriteName: response.data.favouriteName,
          accountNumber: obj.accountNumber,
          swiftRouteBICNumber: obj.swiftRouteBICNumber,
          bankName: obj.bankName,
          iban: obj.iban,
          abaRoutingCode: obj.abaRoutingCode,
          line1: obj.line1,
          line2: obj.line2
        });
        setAddressOptions({
          addressType: response.data.addressType,
          transferType: response.data.transferType,
          domesticType: response.data.transferType,
          tabType: response.data.transferType
        });
        if (obj.iban) {
          getBankDeails(obj.iban);
        }
      } else {
        setRecipientDetails(response.data);
        setLoader(false);
      }
    } else {
      seterrorMessage(isErrorDispaly(response));
      setLoader(false);
    }
  };
  const saveTransfer = async (values) => {
    setBtnLoading(true);
    let saveObj = Object.assign({}, saveTransferObj);
    if (Object.hasOwn(values, "iban")) {
      if (!bankDetails || Object.keys(bankDetails).length === 0) {
        setBtnLoading(false);
        seterrorMessage("Please click validate button before saving");
        useDivRef.current.scrollIntoView();
        return;
      }
    }
    saveObj.favouriteName = values.favouriteName;
    saveObj.payeeAccountModels[0].iban =
      ((currency == "EUR" || currency == "GBP")&&addressOptions?.tabType==="sepa" )||
      addressOptions?.transferType == "internationalIBAN" ||
      addressOptions?.tabType == "internationalIBAN"
        ? values.iban
        : null;
    saveObj.payeeAccountModels[0].accountNumber =
     (currency === "USD"|| addressOptions?.tabType==="swifttransfer" || currency == "GBP" ) ? values.accountNumber : null;
    saveObj.payeeAccountModels[0].abaRoutingCode = values.abaRoutingCode
      ? values.abaRoutingCode
      : null;
    saveObj.payeeAccountModels[0].swiftRouteBICNumber =
      values.swiftRouteBICNumber ? values.swiftRouteBICNumber : null;
    saveObj.payeeAccountModels[0].line1 =
     ((currency === "USD" || currency == "GBP")||addressOptions?.tabType==="swifttransfer") ? values.line1 : null;
    saveObj.payeeAccountModels[0].line2 =
      ((currency === "USD" || currency == "GBP")||( addressOptions?.tabType==="swifttransfer" )) ? values.line2 : null;
    saveObj.payeeAccountModels[0].state = bankDetails.state
      ? bankDetails.state
      : null;
   
    saveObj.payeeAccountModels[0].bankName = bankDetails.bankName ||  values.bankName
    saveObj.payeeAccountModels[0].bic = bankDetails.routingNumber
      ? bankDetails.routingNumber
      : null;
    saveObj.payeeAccountModels[0].ukSortCode = currency == "GBP"? values.ukSortCode : null
    saveObj.payeeAccountModels[0].branch = bankDetails.branch
      ? bankDetails.branch
      : null;
    saveObj.payeeAccountModels[0].country = (currency == "GBP")?values.country:bankDetails.country
      ? bankDetails.country
      : null;
    saveObj.payeeAccountModels[0].city = bankDetails.city
      ? bankDetails.city
      : null;
    saveObj.payeeAccountModels[0].postalCode = bankDetails.zipCode
      ? bankDetails.zipCode
      : null;
    saveObj.firstName = recipientDetails.firstName;
    saveObj.lastName = recipientDetails.lastName;
    saveObj.beneficiaryName = recipientDetails.beneficiaryName;
    saveObj.line1 = recipientDetails.line1;
    saveObj.line2 = recipientDetails.line2;
    saveObj.line3 = recipientDetails.line3;
    saveObj.addressType = isBusiness ? "ownbusiness" : "myself";
    saveObj.transferType = ((currency == "EUR" || currency == "GBP") &&addressOptions.tabType==="sepa") ? "sepa" : addressOptions.tabType;
    saveObj.payeeAccountModels[0].currencyType = "fiat";
    saveObj.payeeAccountModels[0].walletCode = currency;
    saveObj.createdBy = props.userConfig?.userName;
    const response = await saveTransferData(saveObj);
    if (response.ok) {
      if (props.type !== "manual") {
        props.onContinue(response.data);
        setBtnLoading(false);
        form?.current?.scrollIntoView(0,0);
      } else {
        setShowDeclartion(true);
      }
    } else {
      seterrorMessage(isErrorDispaly(response));
      useDivRef.current.scrollIntoView();
      setBtnLoading(false);
    }
  };
  const getBankDeails = async (e,isValid) => {
    setbankDetails({});
    setEnteredIbanData(e);
    seterrorMessage(null);
    setIsShowValid(false);
    setIsShowBankDetails(false);
    if(e?.length>=10&&isValid){
        setValidIban(true) 
        isValid ? setIbanLoader(true) : setIbanLoader(false);
        const response = await getIBANData(e,props?.selectedBank);
        if (response.ok) {
            if(response.data && (response.data?.routingNumber || response.data?.bankName)){
                setValidIban(true)
                setIsShowBankDetails(true);
                setIsBankDetailsLoading(false);
                setbankDetails(response.data);
            }else{
                setIsBankDetailsLoading(false);
                 setValidIban(false)
                if (bankDetails && (!bankDetails?.routingNumber || !bankDetails?.bankName)) {
                    setIsShowBankDetails(false);
                    setIbanLoader(false)
                    setbankDetails({})
                    seterrorMessage("No bank details are available for this IBAN number");
                    useDivRef.current.scrollIntoView();
                    return;
                }
            }
          setIbanLoader(false)
        }else{
            seterrorMessage(isErrorDispaly(response));
            setbankDetails({})
            setIbanLoader(false)
            setValidIban(false)
            setIsBankDetailsLoading(false);
        }
    }
    if(e?.length>=10&&!isValid) {
        setValidIban(true); 
        setIsShowBankDetails(false);
        setIsBankDetailsLoading(false);
    } 
}

  const onIbanValidate = (e) => {
    setIsBankDetailsLoading(true);
    seterrorMessage(null);
    if (e?.length >= 10) {
      if (e &&!/^[A-Za-z0-9]+$/.test(e)) {
          setIsShowValid(true);
          setIsShowBankDetails(false);
          setbankDetails({});
          form?.validateFields(["iban"], validateIbanType)
      }
      else {
          setIsShowValid(false);
          getBankDeails(e, "true");
      }
  }
  else {
      setIsShowValid(true);
      setValidIban(false);
      setIsShowBankDetails(false); 
      setbankDetails({});
      form?.validateFields(["iban"], validateIbanType)
  }
  };

  const validateIbanType = (_, value) => {
    setIsBankDetailsLoading(false);
    if ((!value && isShowValid) || !value) {
      setIsShowBankDetails(false);
      return Promise.reject("Is required");
    } else if ((!validIban && isShowValid) || value?.length < 10) {
      setIsShowBankDetails(false);
      setbankDetails({});
      return Promise.reject("Please input a valid IBAN");
    } else if (value && isShowValid && !/^[A-Za-z0-9]+$/.test(value)) {
      setIsShowBankDetails(false)
      setbankDetails({})
      return Promise.reject("Please input a valid IBAN")
    } else {
      return validateContentRule(_, value);
    }
  };
const handleTabChange=(activekey) => {
    setAddressOptions({ ...addressOptions, domesticType: activekey, tabType: activekey });
    form.resetFields();
    seterrorMessage(null);
    setbankDetails({});setValidIban(false); 
    setEnteredIbanData(null); 
}
const getCountry = async () => {
  let response = await getCountryStateLu();
  if (response.ok) {
    setCountry(response.data);
    form.getFieldValue("country");
  }else{
    seterrorMessage(isErrorDispaly(response));
  }
}
const renderCountryOptions = () => {
  return country?.map((item) => (
    <Option key={item?.name} value={item.name}>
      {item.name}
    </Option>
  ));
};
const handleCountry = () => {
  form.setFieldsValue({ "state": null });
}
  return (
    <>
      <div ref={useDivRef}></div>
      {isLoading && <Loader />}
      {!isLoading && (
        <Form
          layout="vertical"
          form={form}
          onFinish={saveTransfer}
          initialValues={{ createTransfer }}
          scrollToFirstError
        >
          {showDeclartion && (
            <div className="text-center">
            {process.env.REACT_APP_ISDOCUSIGN == "false" && <> <Image width={80} preview={false} src={alertIcon} className="confirm-icons" />
              <Title level={2} className="success-title">
                Declaration form sent successfully to your email
              </Title>
              <Text className="successsubtext">{`Declaration form has been sent to ${props.userProfile?.email}. 
                   Please sign using link received in email to whitelist your address. `}</Text>
              <Text className="successsubtext">{`Please note that your withdrawal will only be processed once your whitelisted address has been approved`}</Text>
              <div className="my-25">
              
              </div></> || <div className="success-pop text-center declaration-content">
       <Image  preview={false} src={success}  className="confirm-icon" />
       <Title level={2} className="success-title">Address saved successfully</Title>
       {/* <p className="text-white">Please note that any transactions regarding this whitelist will only be processed once your whitelisted address has been approved</p> */}
   </div>}
            </div>
          )}
          {!showDeclartion && (
            <>
              {" "}
              {currency === "USD" && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="">
                    <Tabs style={{ color: '#fff' }} className="cust-tabs-fait fiat-tabscls" onChange={(activekey) => { setAddressOptions({ ...addressOptions, domesticType: activekey, tabType: activekey });form.resetFields();seterrorMessage(null);setbankDetails({});setValidIban(false); setEnteredIbanData(null) }} activeKey={addressOptions.tabType} >

                        <Tabs.TabPane
                          tab="Domestic USD Transfer"
                          className="text-white"
                          key={"domestic"}
                        ></Tabs.TabPane>
                        <Tabs.TabPane
                          tab="International USD Swift"
                          className="text-white"
                          key={"international"}
                        ></Tabs.TabPane>
                        <Tabs.TabPane
                          tab="International USD IBAN"
                          className="text-white text-captz"
                          key={"internationalIBAN"}
                        ></Tabs.TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </>
              )}
               {(currency == "EUR") && (
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="">
                    <Tabs activeKey={addressOptions.tabType} style={{ color: '#fff' }} className="cust-tabs-fait fiat-tabscls" 
                   onChange={handleTabChange}>
                        <Tabs.TabPane
                          tab="SEPA Transfer"
                          className="text-white"
                          key={"sepa"}
                        ></Tabs.TabPane>
                         {/* <Tabs.TabPane
                          tab="SWIFT Transfer"
                          className="text-white"
                          key={"swifttransfer"}
                        ></Tabs.TabPane> */}
                        
                       
                      </Tabs>
                    </Col>
                  </Row>
              )}
                {(currency == "GBP") && (
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="">
                    <Tabs activeKey={addressOptions.tabType} style={{ color: '#fff' }} className="cust-tabs-fait fiat-tabscls" 
                   onChange={handleTabChange}>
                        <Tabs.TabPane
                          tab="Local GBP Transfer"
                          className="text-white"
                          key={"domestic"}
                        ></Tabs.TabPane>
                      </Tabs>
                    </Col>
                  </Row>
              )}
              {errorMessage && (
                <Alert
                  type="error"
                  showIcon
                  closable={false}
                  description={errorMessage}
                />
              )}
              {!isLoading && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col
                      xs={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      id="favoriteName"
                    >
                      <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="favouriteName"
                        label={"Save Whitelist Name As"}
                        required
                        rules={[
                          {
                              validator:(_,val)=>{
                                  return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'save whitelist name as'})
                              }
                          },
                          {
                              whitespace: true,
                              message: "Is required",
                          },
                      ]}
                      >
                        <Input
                          maxLength={100}
                          className="cust-input"
                          placeholder="Save Whitelist Name As"
                        />
                      </Form.Item>
                    </Col>
                  
                  </Row>
                 
                  <h2
                    className="adbook-head"
                  >
                    Recipient's Details
                  </h2>

                  <div className="alert-info-custom kpi-List">
                    <Row>
                      {!isBusiness && (
                        <>
                          <Col
                            xs={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                           
                          > <div className="kpi-divstyle">
                            <label className="kpi-label">
                                First name
                            </label>
                            <div>
                              <Text className="kpi-val">
                                {recipientDetails.firstName}
                              </Text>
                            </div></div>
                          </Col>
                          <Col
                            xs={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                           
                          >
                             <div className="kpi-divstyle"> <label className="kpi-label">
                            Last Name
                        </label>
                            <div>
                              <Text className="kpi-val">
                                {recipientDetails.lastName}
                              </Text>
                            </div></div>
                          </Col>
                        </>
                      )}
                      {isBusiness && (
                        <Col
                          xs={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                        
                        >
                         <div className="kpi-divstyle"><label className="kpi-label">
                        Beneficiary Name
                    </label>
                          <div>
                            <Text className="kpi-val">
                              {recipientDetails.beneficiaryName}
                            </Text>
                          </div></div>
                        </Col>
                      )}
                      <Col
                        xs={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24} 
                      >
                        <div className="kpi-divstyle"> <label className="kpi-label">
                        Address Line 1
                    </label>
                        <div>
                          <Text className="kpi-val">
                            {recipientDetails.line1 != null
                              ? recipientDetails.line1
                              : "-"}
                          </Text>
                        </div></div>
                      </Col>
                      <Col
                        xs={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                      
                      >
                        <div className="kpi-divstyle"> <label className="kpi-label ">
                        Address Line 2
                    </label>
                        <div>
                          <Text className="kpi-val">
                            {recipientDetails.line2 != null
                              ? recipientDetails.line2
                              : "-"}
                          </Text>
                        </div></div>
                      </Col>
                      <Col
                        xs={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                       
                      >
                        <div className="kpi-divstyle"><label className="kpi-label">
                       Address Line 3
                    </label>
                        <div>
                          <Text className="kpi-val">
                            {recipientDetails.line3 != null
                              ? recipientDetails.line3
                              : "-"}
                          </Text>
                        </div></div>
                      </Col>
                    </Row>
                  </div>

                  <Paragraph
                    className="adbook-head"
                  >
                    Bank Details
                  </Paragraph>
                  {(((currency == "EUR") && addressOptions.tabType==="sepa") ||(
                    addressOptions.tabType == "internationalIBAN")) && (
                    <Row className="validateiban-content">
                      {((currency == "EUR" && addressOptions.tabType==="sepa")||
                        addressOptions.tabType == "internationalIBAN") && (
                        <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                          <div className="custom-btn-error">
                            <Form.Item
                              className="custom-forminput custom-label "
                              name="iban"
                              required
                              rules={[
                                {
                                  validator: validateIbanType
                                }
                              ]}
                              label="IBAN"
                              onChange={(e) => {
                                getBankDeails(e.target.value);
                              }}
                            >
                              <Input
                                className="cust-input ibanborder-field"
                                placeholder="IBAN"
                                maxLength={50}
                                addonAfter={ <Button
                            className={`px-24`}
                            type="primary"
                            loading={isBankDetailsLoading}
                            onClick={() => onIbanValidate(enteredIbanData)}
                          >
                            <Text>Validate</Text>
                          </Button>}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      )}
                    </Row>
                  )}

                  <Row gutter={[8, 8]}>
                    {(currency == "USD"  || (currency == "EUR" && addressOptions.tabType==="swifttransfer") )|| (currency == "GBP") &&
                      addressOptions.tabType !== "internationalIBAN" && (
                        <>
                          {" "}
                          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                              className="fw-300 mb-8 px-4 text-white-50  custom-forminput custom-label pt-8"
                              name="accountNumber"
                              label="Account Number"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Is required"
                                },
                                {
                                  validator: (_, value) => {
                                    if (
                                      value &&
                                      !/^[A-Za-z0-9]+$/.test(value)
                                    ) {
                                      return Promise.reject(
                                        "Invalid Account Number"
                                      );
                                    } else {
                                      return validateContentRule(_, value);
                                    }
                                  }
                                }
                              ]}
                            >
                              <Input
                                className="cust-input"
                                placeholder="Account Number"
                                maxLength={50}
                              />
                            </Form.Item>
                          </Col>
                          {(currency == "USD" ) &&
                            addressOptions.tabType == "international" && (
                              <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item
                                  className="custom-forminput custom-label fw-400 mb-8 px-4 text-white pt-8"
                                  name="swiftRouteBICNumber"
                                  label={
                                    currency == "USD" &&
                                    addressOptions.tabType == "international"
                                      ? "Swift / BIC Code"
                                      : "ABA Routing Code"
                                  }
                                  required
                                  rules={[
                                    {
                                      required: true,
                                      message: "Is required"
                                    },
                                    {
                                      validator: (_, value) => {
                                        if (
                                          value &&
                                          !/^[A-Za-z0-9]+$/.test(value)
                                        ) {
                                          return Promise.reject(
                                            addressOptions.tabType ===
                                              "international"
                                              ? "Invalid Swift / BIC Code"
                                              : "Invalid ABA Routing Code"
                                          );
                                        } else {
                                          return validateContentRule(_, value);
                                        }
                                      }
                                    }
                                  ]}
                                >
                                  <Input
                                    className="cust-input"
                                    placeholder={
                                      currency === "USD" &&
                                      addressOptions.tabType === "international"
                                        ? "Swift / BIC Code"
                                        : "ABA Routing Code"
                                    }
                                    maxLength={50}
                                  />
                                </Form.Item>
                              </Col>
                            )}
                          {((currency == "EUR")&& addressOptions.tabType==="swifttransfer") && (
                          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                              <Form.Item
                                className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 pt-8"
                                name="swiftRouteBICNumber"
                                label={"Swift / BIC Code" 
                                }
                                required
                                rules={[
                                  {
                                    required: true,
                                    message: "Is required"
                                  },
                                  {
                                    validator: (_, value) => {
                                      if (
                                        value &&
                                        !/^[A-Za-z0-9]+$/.test(value)
                                      ) {
                                        return Promise.reject("Invalid Swift / BIC Code"
                                          
                                        );
                                      } else {
                                        return validateContentRule(_, value);
                                      }
                                    }
                                  }
                                ]}
                              >
                                <Input
                                  className="cust-input"
                                  placeholder={"Swift / BIC Code"}
                                  maxLength={50}
                                />
                              </Form.Item>
                            </Col>
                          )}
                            {(currency == "GBP") && (
                          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                              <Form.Item
                                className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 pt-8"
                                name="ukSortCode"
                                label={"Uk Sort Code"}
                                required
                                rules={[
                                  {
                                    required: true,
                                    message: "Is required"
                                  },
                                  {
                                    validator: (_, value) => {
                                      if (
                                        value &&
                                        !/^[A-Za-z0-9]+$/.test(value)
                                      ) {
                                        return Promise.reject("Invalid Uk Sort Code");
                                      } else {
                                        return validateContentRule(_, value);
                                      }
                                    }
                                  }
                                ]}
                              >
                                <Input
                                  className="cust-input"
                                  placeholder={"Uk Sort Code"}
                                  maxLength={ 6 }
                                />
                              </Form.Item>
                            </Col>
                          )}
                         <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                              className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 pt-8"
                              name="bankName"
                              label="Bank Name"
                              required
                              rules={[
                                {
                                    validator:(_,val)=>{
                                        return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'save whitelist name as'})
                                    }
                                },
                                {
                                    whitespace: true,
                                    message: "Is required",
                                },
                            ]}
                            >
                              <Input
                                className="cust-input"
                                placeholder="Bank Name"
                                maxLength={100}
                              />
                            </Form.Item>
                          </Col>
                          { currency === 'GBP' && (
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                       className="custom-forminput custom-label px-4"
                        name="country"
                        label={"Bank Country"}
                        required
                        rules={[
                          {
                            required: true,
                            message: "Is required",
                          },
                          {
                            whitespace: true,
                            message: "Is required",
                          }, 
                          {
                            validator: validateContentRule,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder={"Select Country"}
                          className="cust-input select-crypto "
                          dropdownClassName="select-drpdwn"
                          onChange={() => handleCountry()}
                          bordered={false}
                        >
                          {renderCountryOptions()} 
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                              className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 pt-8"
                              name="line1"
                              label="Bank Address 1"
                              required
                              rules={[
                                {
                                    validator:(_,val)=>{
                                        return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true})
                                    }
                                },
                                {
                                    whitespace: true,
                                    message: "Is required",
                                },
                            ]}
                            >
                              <TextArea
                                placeholder={"Bank Address 1"}
                                className="cust-input cust-text-area address-book-cust"
                                autoSize={{ minRows: 1, maxRows: 2 }}
                                maxLength={1000}
                              ></TextArea>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                              className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 pt-8"
                              name="line2"
                              label="Bank Address 2"
                              rules={[
                                {
                                    validator:(_,val)=>{
                                        return validateFeild({val:val,maxLength:50,validateContentRule:true})
                                    }
                                }
                            ]}
                            >
                              <TextArea
                                placeholder={"Bank Address 2"}
                                className="cust-input cust-text-area address-book-cust"
                                autoSize={{ minRows: 1, maxRows: 2 }}
                                maxLength={1000}
                              ></TextArea>
                            </Form.Item>
                          </Col>
                        </>
                      )}
                  </Row>

                  {(((currency == "EUR") && addressOptions.tabType==="sepa") ||
                    addressOptions.tabType == "internationalIBAN") && (
                    <div className="box basic-info alert-info-custom mt-16 kpi-List">
                      <Spin spinning={ibanLoading}>
                        {validIban && isShowBankDetails &&(
                          <Row>
                            <Col
                              xs={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              
                            >
                              <div className="kpi-divstyle">
                              <label className="kpi-label">
                                      Bank Name
                              </label>
                              <div>
                                <Text className="kpi-val">
                                  {bankDetails?.bankName != "" &&
                                  bankDetails?.bankName != null
                                    ? bankDetails?.bankName
                                    : "-"}
                                </Text>
                              </div></div>
                            </Col>
                            <Col
                              xs={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                             
                            >
                             <div className="kpi-divstyle">
                              <label className="kpi-label ">BIC</label>
                              <div>
                                <Text className="kpi-val">
                                  {" "}
                                  {bankDetails.routingNumber != "" &&
                                  bankDetails.routingNumber != null
                                    ? bankDetails.routingNumber
                                    : "-"}
                                </Text>
                              </div></div>
                            </Col>
                            <Col
                              xs={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                            
                            >
                             <div className="kpi-divstyle">
                              <label className="kpi-label ">Branch</label>
                              <div>
                                <Text className="kpi-val">
                                  {bankDetails.branch != "" &&
                                  bankDetails.branch != null
                                    ? bankDetails.branch
                                    : "-"}
                                </Text>
                              </div></div>
                            </Col>
                            <Col
                              xs={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                             
                            >
                            <div className="kpi-divstyle">
                                <label className="kpi-label">Country</label>
                              <div>
                                <Text className="kpi-val">
                                  {bankDetails.country != "" &&
                                  bankDetails.country != null
                                    ? bankDetails.country
                                    : "-"}
                                </Text>
                              </div></div>
                            </Col>
                            <Col
                              xs={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              
                            >
                             <div className="kpi-divstyle">
                           <label className="kpi-label">State</label>
                              <div>
                                <Text className="kpi-val">
                                  {bankDetails.state != "" &&
                                  bankDetails.state != null
                                    ? bankDetails.state
                                    : "-"}
                                </Text>
                              </div></div>
                            </Col>
                            <Col
                              xs={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                             
                            >
                              <div className="kpi-divstyle">
                        <label className="kpi-label">City</label>
                              <div>
                                <Text className="kpi-val">
                                  {bankDetails.city != "" &&
                                  bankDetails.city != null
                                    ? bankDetails.city
                                    : "-"}
                                </Text>
                              </div></div>
                            </Col>
                            <Col
                              xs={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                             
                            >
                              <div className="kpi-divstyle">
                               <label className="kpi-label">Zip</label>
                              <div>
                                <Text className="kpi-val">
                                  {bankDetails.zipCode != "" &&
                                  bankDetails.zipCode != null
                                    ? bankDetails.zipCode
                                    : "-"}
                                </Text>
                              </div></div>
                            </Col>
                          </Row>
                        )}

                        {(!validIban ||!isShowBankDetails) && <span className="info-details">No bank details available</span>}
                      </Spin>
                    </div>
                  )}

                  <div className="text-right mt-36">
                    <Button
                      htmlType="submit"
                      size="large"
                      className="pop-btn px-36"
                      block
                      loading={isBtnLoading}
                    >
                      {props.type === "manual" && "Save"}
                      {props.type !== "manual" && "Continue"}
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Form>
      )}
    </>
  );
};
const connectStateToProps = ({ userConfig }) => {
  return {
    userConfig: userConfig.userProfileInfo
  };
};
export default connect(connectStateToProps)(MyselfNewTransfer);
