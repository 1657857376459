import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import authReducer from '../reducers/authReducer';
import UserConfig from '../reducers/configReduser';
import featuresReducer from '../reducers/featuresReducer';
import receiveReducer from '../reducers/receiveReducer';
import digitalWalletReducer from '../reducers/digitalWalletReducer';
import detailsReducer from '../reducers/detailsReducer'
import currentActionReducer from '../reducers/actionsReducer';

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth", "userConfig","menuItems","currentAction","permissions"]
}
const rootReducer = combineReducers({
    auth: authReducer,
    userConfig: UserConfig,
    receiveData:receiveReducer,
    digitalWalletData:digitalWalletReducer,
    menuItems:featuresReducer,
    customerDetails:detailsReducer,
    currentAction:currentActionReducer,
});
const reducer = persistReducer(persistConfig, rootReducer)
let store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(thunk)),
);
const persistor = persistStore(store);

export { store, persistor }