import React, { Component } from "react";
import { Form, Col, Select, AutoComplete, Input } from 'antd'
import ConnectStateProps from "../../../utils/state.connect";
import { validateContentRule,validateFeild } from "../../../utils/custom.validator";
import BankDetails from './bank.details';

const { Option } = Select;
const { TextArea } = Input
class RecipientAddress extends Component {
    render() {
        return <React.Fragment>

            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="line1"
                    required
                    rules={[
                        {
                            validator:(_,val)=>{
                                return validateFeild({val:val,required: true,maxLength:250,validateContentRule:true,lableName:'address line 1'})
                            }
                        },
                        {
                            whitespace: true,
                            message: "Is required",
                        },
                    ]}
                    label="Address Line 1"
                >
                    <TextArea
                        placeholder="Address Line 1"
                        className="cust-input cust-text-area address-book-cust"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                    ></TextArea>
                </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="line2"
                    rules={[
                        {
                            validator:(_,val)=>{
                                return validateFeild({val:val,maxLength:250,validateContentRule:true,lableName:'address line 2'})
                            }
                        },
                    ]}
                    label="Address Line 2"
                >
                    <TextArea
                        placeholder="Address Line 2"
                        className="cust-input cust-text-area address-book-cust"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                    ></TextArea>
                </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="line3"
                    rules={[
                        {
                            validator:(_,val)=>{
                                return validateFeild({val:val,maxLength:250,validateContentRule:true,lableName:'address line 1'})
                            }
                        },
                    ]}
                    label="Address Line 3"
                >
                    <TextArea
                        placeholder="Address Line 3"
                        className="cust-input cust-text-area address-book-cust"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                    ></TextArea>
                </Form.Item>
            </Col>
        </React.Fragment>
    }
}

class RecipientDetails extends Component {
    state = { emailExist: false, payeeLu: [] }
    render() {
        const {payeeLu } = this.props;
        return <React.Fragment>
            <Col xs={24} md={12} lg={12} xl={12} xxl={12} id="favoriteName">
                <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="favouriteName"
                    label={
                        "Save Whitelist name as"
                    }
                    required
                    rules={[
                        {
                            required: true,
                            message: "Is required",
                        },
                        {
                            whitespace: true,
                            message: "Is required",
                        },
                        {
                            validator: validateContentRule,
                        },
                    ]}
                >
                    <AutoComplete
                        maxLength={100}
                        className="cust-input"
                        placeholder={"Save Whitelist name as"}
                    >
                        {payeeLu?.map((item, indx) => (
                            <Option key={indx} value={item.name}>
                                {item.name}
                            </Option>
                        ))}
                    </AutoComplete>
                </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="firstName"
                    required
                    rules={[
                        {
                            whitespace: true,
                            message: "Is required",
                        },
                        {
                        validator:(_,val)=>{
                            return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'first name'})
                        }
                    },
                    ]}
                    label={
                        "First Name"
                    }
                >
                    <Input
                        className="cust-input"
                        placeholder={"First Name"}
                    />
                </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="lastName"
                    required
                    rules={[
                        {
                            whitespace: true,
                            message: "Is required",
                        },
                        {
                        validator:(_,val)=>{
                            return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'save whitelist name as'})
                        }
                    },
                    ]}
                    label={
                        "Last Name"
                    }
                >
                    <Input
                        className="cust-input"
                        placeholder={"Last Name"}
                    />
                </Form.Item>
            </Col>
            <RecipientAddress />
            <BankDetails transferType={this.props.transferType} />
        </React.Fragment>
    }
}
export default ConnectStateProps(RecipientDetails);

export {RecipientAddress}