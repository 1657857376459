import { Component } from "react";
import {  } from '../api/apiCalls';

class Home extends Component{


    render(){
        return <><h1>Home</h1></>
    }
}

export default Home;