import React, { Component } from 'react';
import { connect } from 'react-redux';

class NotKyc extends Component {
    state = {loading:true}
    render() {
        return (
            <>
            <div className="notkyc">
             <div>Dear customer please complete your
            <a  onClick={() =>
                            window.open(
                              process.env.REACT_App_EXCHANGE_URL +
                                `/sumsub`,
                              "_self"
                            )
                          }>{this.props.userConfig.isBusiness?' KYB ':' KYC '}</a>
            to continue 
           
            </div></div>
            </>
        );
    }
}

const connectStateToProps = ({ userConfig }) => {
    return { userConfig: userConfig.userProfileInfo }
}

export default connect(connectStateToProps)(NotKyc);