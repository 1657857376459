import React, { useEffect, useState } from "react";
import List from "antd/lib/list";
import Alert from "antd/lib/alert";
import Image from "antd/lib/image";
import Empty from "antd/lib/empty";

import { connect } from 'react-redux';
import Loader from "../shared/loader";
import {getCustomerBankDetails,isErrorDispaly} from "../../api/apiCalls";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import {setReceiveStep,setReceiveObj} from '../../reducers/receiveReducer'


const ReceiveFunds = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [getCurrency, setGetCurrency] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedCurrency]=useState(props.currency)

  useEffect(() => {
    if(!selectedCurrency ){
      getCurrencyDetails()
    }else{
      props.changeStep('receiveFunds')
    }
  }, []);

  const getCurrencyDetails = async() =>{
    setIsLoading(true)
  let response =  await getCustomerBankDetails()
  if(response.ok){
    setIsLoading(false)
    setGetCurrency(response.data)
    props.dispatch(setReceiveObj(response.data))
  }else{
    setIsLoading(false)
    setMessage(isErrorDispaly(response));
  }
  }

 
const fiatDetails=(id)=>{
    props.dispatch(setReceiveObj(id))
    props.changeStep('receiveFunds')
    
    
}
  return <>
    {message !== undefined && message !== null && message !== "" && (
      <div style={{ width: "100%" }}>
        <Alert
          className="w-100 mb-16"
          type="warning"
          description={message}
          showIcon
        />
      </div>
    )}
    <div className="mb-36">
      {isLoading ? (<Loader />) : (
        <>
         <List
                    itemLayout="horizontal"
                    dataSource={getCurrency}
                    className="crypto-list auto-scroll wallet-list c-pointer"
                    loading={isLoading}
                    locale={{
                        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                            "No Data Found"
                        } />
                    }}
                    renderItem={item => {
                        if(item.accountStatus=="Approved"){
                            return <List.Item  
                            onClick={()=>fiatDetails(item)}
                           
                            >
                            <Link>
                                <List.Item.Meta
                                    avatar={<Image preview={false} src={item.imagePath} />}

                                    title={<div className="wallet-title">{item.currency}</div>}
                                   
                                />

                                <><div className="text-right coin-typo">
                                    {item.availableBalance !== 0 && <NumberFormat value={item.availableBalance} className="text-white-30 fw-600" displayType={'text'} thousandSeparator={true} prefix={item.currency == 'USD' ? '$' : '€'} renderText={(value) => <div {...props} >{value}</div>} />}

                                </div></>
                            </Link>
                        </List.Item>
                        }
                    }}
                />
        
        </>)}
    </div>
  </>
}
const connectStateToProps = ({ userConfig ,receiveData}) => {
  return {
    userConfig: userConfig.userProfileInfo,
    receiveData:receiveData
    
  };
};
const connectDispatchToProps = dispatch => {
    return {
        changeStep: (stepcode) => {
            dispatch(setReceiveStep(stepcode))
        },
        dispatch
    }
}
export default connect(connectStateToProps,connectDispatchToProps)(ReceiveFunds);