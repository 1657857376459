import Result from 'antd/lib/result';
import Button from 'antd/lib/button';
import React from 'react'
import ConnectStateProps from '../../utils/state.connect';

const TwoFactor = () => {
    return <React.Fragment style={{ top: 10 }}>
        <div className="main-container">
            < Result status="404"
                title={<h4 className="text-white">Please click the button below to enable 2FA.</h4>}
                extra={<Button className="pop-btn px-36 c-pointer" 
                onClick={()=>window.open(`${process.env.REACT_App_EXCHANGE_URL}/userprofile/2`,"_self")}
                >Go to Security</Button>}
            />
        </div>
    </React.Fragment>
}

export default ConnectStateProps(TwoFactor)