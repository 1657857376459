import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import  message  from "antd/lib/message";
import Alert from "antd/lib/alert";
import DatePicker from "antd/lib/date-picker";
import { connect } from "react-redux";
import { saveAccount, getCustomerData,getCountryStateLu,getAddressTypeLU,isErrorDispaly} from "../../api/apiCalls";
import Loader from "../shared/loader";
import moment from "moment";
import { validateContentRule ,validateFeild} from "../../utils/custom.validator";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";

const { Title, Text } = Typography;
const { Option } = Select;

const BusinessCreateAccount = (props) => {
  const [form] = Form.useForm();
  const [accountloading, setAccountloading] = useState(false);
  const [error, setError] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [addressType,setAddressType] = useState([])
  let history = useHistory()

  useEffect(() => {
    getData();
    getCountryLU();
    handleCountry();
    handleChange();
    getAddressDropDownLU();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    let response = await getCustomerData();
    if (response.ok) {
      setError(null)
      setCustomerData(response.data);
      getCountryLU(response.data?.address?.country);
      setIsLoading(false);
    } else {
      setError(isErrorDispaly(response));
      setIsLoading(false);
    }
  };

  function disabledDate(current) {
    const weekEnd = moment().endOf("day");

    return !weekEnd.isAfter(current);
  }
  const savedetails = async (values) => {
    setAccountloading(true);
    setError(null);
    let objs = {
      legalName: props.userConfig.businessName,
      legalStatus: customerData.legalStatus,
      dateofIncorporation: moment(customerData.dateofIncorporation).format(
        "YYYY-MM-DD"
      ),
     
      email: customerData.email,
      idNumber: values.idNumber,
      idType: values.idType,
      currency: props.currency,
      address: values.address,
      IsBusiness: props.userConfig.isBusiness,
      customerId: props.userConfig.id,
      created_by: customerData.created_by,
      companyRegistrationNumber: values.companyRegistrationNumber,
      websiteUrls: values.websiteUrls,
      BusinessPhoneNo: customerData.phone,
      phone: customerData.phone,
      programmeuid: values.programmeuid,
      entityType: values.entityType,
      dateOfIncorporation: values.dateOfIncorporation,
      industry: values.industry,
      OneTimeFee:props?.FeesData?.account ? props?.FeesData?.account["Monthly Fee"] : null,
      MonthlyFee:props?.FeesData?.account ? props?.FeesData?.account["One Time Fee"] : null,
      bankProvider:props?.isbank
    };

    const response = await saveAccount(objs);
    if (response.ok) {
      setError(null)
      setAccountloading(false);
      message.destroy();
      message.success({
        content: "Account created successfully",
        className: "custom-msg",
        duration: 4
      });
      history.push(`/progressPage/${props?.currency}/${props?.isbank?.toLowerCase()}`);
    } else {
      setError(isErrorDispaly(response));
      setAccountloading(false);
      window.scrollTo(0, 0);
    }
  };
  const getCountryLU = async (countryName) => {
    let res = await getCountryStateLu();
    if (res.ok) {
      setError(null)
      setCountry(res.data);
      if (countryName) {
        let filteredCountry = res.data.filter((countrys) => {
            return countrys.code && countryName && countrys?.code?.toLowerCase() === countryName?.toLowerCase();
        });
        if (filteredCountry.length > 0) {
          setStates(filteredCountry[0].stateLookUp);
        }
      }
    }else{
      setError(isErrorDispaly(res));
    }
  };
  const handleCountry = (state, stateLuc) => {
    form.setFieldsValue({ state: " " });
    setStates([]);
    let selectCountry = stateLuc ? stateLuc : country;
    selectCountry?.map((item) => {
      if (state === item.code) {
        setStates(item?.stateLookUp);
      }
    });
  };
  const handleChange = (code, stateLuc) => {
    let selectCountry = stateLuc ? stateLuc : country;
    if (code?.toLowerCase() === selectCountry.code?.toLowerCase()) {
      setStates(country.stateLookUp);
    }
  };

  const getAddressDropDownLU= async ()=>{
    let response = await getAddressTypeLU()
    if(response.ok){
      setAddressType(response.data)
      setError(null)
    }else{
      setError(isErrorDispaly(response));
    }
   }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Form
          form={form}
          onFinish={savedetails}
          autoComplete="off"
          initialValues={customerData}
          scrollToFirstError={{
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          }}
        >
          <div className="creat-bg">
            
            {error != undefined && error != null && (
              <div className="custom-createaccount style-none">
              <Alert
                className="w-100 mb-16"
                type="error"
                showIcon
                description={error}
              /></div>
            )}
            <div className="custom-createaccount cust-bg-remove business-account">
            <div className="cust-scroll" >
              <Title className="db-titles ">
                Review your Details
              </Title>
            </div>
              <div className="box basic-info alert-info-custom">
              <Title
                className="adbook-head mt-0">
                Company Details{" "}
              </Title>
                <Row gutter={24} className="pb-8 ">
                  <Col xs={24} sm={24} md={12} className="mb-16 " >
                    <Text className="case-lbl">Legal Name </Text>
                    <div className="case-val">
                      {props.userConfig.isBusiness
                        && props.userConfig.businessName}
                       {!props.userConfig.isBusiness && props.userConfig.firstName}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} className="mb-16 ">
                    <Text className="case-lbl">Currency </Text>
                    <div className="case-val">                     
                      {props.currency || "--"}
                    </div>
                  </Col>
                   <Col xs={24} sm={24} md={12} className="mb-16">
                      <Text className="case-lbl"> Phone </Text>
                      <div className="case-val">{customerData.phone}</div>
                    </Col>                 
                   <Col xs={24} sm={24} md={12} className="mb-16">
                      <Text className="case-lbl">Email</Text>
                      <div className="">
                          <span className="ant-typography case-val">{customerData.email || "--"}</span>
                      </div>
                    </Col>                
                  {props.currency == "EUR" && (
                    <Col xs={24} sm={24} md={12} className="mb-16">
                      <Text className="case-lbl">Legal Status </Text>
                      <div className="case-val">
                          {customerData.legalStatus || "--"}
                      </div>
                    </Col>
                  )}                            
                    <Col xs={24} md={12} lg={12} xl={12} xxl={12} className="input-pl mb-16">
                      <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="entityType"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Is required"
                          },
                          {
                            whitespace: true,
                            message: "Is required"
                          },
                          {
                            validator: validateContentRule
                          }
                        ]}
                        label={"Entity Type"}
                      >                       
                         <Select
                          showSearch
                          placeholder="Entity Type"
                          className="cust-input select-crypto cust-adon mb-0 text-left c-pointer"
                          dropdownClassName="select-drpdwn"
                          disabled={customerData?.entityType}
                           >
                          <Option value="LIMITED_LIABILITY">LIMITED LIABILITY</Option>
                          <Option value="SOLE_TRADER">SOLE TRADER</Option>
                          <Option value="PARTNERSHIP">PARTNERSHIP</Option>
                          <Option value="PUBLIC_LIMITED_COMPANY">PUBLIC LIMITED COMPANY</Option>
                          <Option value="JOINT_STOCK_COMPANY">JOINT STOCK COMPANY</Option>
                          <Option value="CHARITY">CHARITY</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  
                  {props.currency == "USD" && (                   
                    <Col xs={24} md={12} lg={12} xl={12} xxl={12} className="input-pl mb-16">
                      <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="industry"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Is required"
                          },
                          {
                            whitespace: true,
                            message: "Is required"
                          },
                          {
                            validator: validateContentRule
                          }
                        ]}
                        label={"Industry"}
                      >
                        <Select
                          showSearch
                          placeholder="Entity Type"
                          className="cust-input select-crypto cust-adon mb-0 text-left c-pointer"
                          dropdownClassName="select-drpdwn"
                        >
                          <Option value="Aerospace Industry">Aerospace Industry</Option>
                          <Option value="Hospitality Industry">Hospitality Industry</Option>
                          <Option value="Healthcare Industry">Healthcare Industry</Option>
                          <Option value="News Media Industry">News Media Industry</Option>
                          <Option value="Energy Industry">Energy Industry</Option>
                          <Option value="Manufacturing Industry">Manufacturing Industry</Option>
                          <Option value="Transport Industry">Transport Industry</Option>
                          <Option value="Advertisement Industry">Advertisement Industry</Option>
                          <Option value="Computer Industry">Computer Industry</Option>
                          <Option value="Telecommunication Industry">Telecommunication Industry</Option>
                          <Option value="Education Industry">Education Industry</Option>
                          <Option value="Pharmaceutical Industry">Pharmaceutical Industry</Option>
                          <Option value="Entertainment Industry">Entertainment Industry</Option>
                          <Option value="Fintech Industry">Fintech Industry</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                  )}
                  
                  {customerData?.dateOfIncorporation !== null  ?
                  
                  (
                    <Col xs={24} sm={24} md={12} className="mb-16">
                      <Text className="case-lbl">Formation Date </Text>
                      <div className="case-val">
                      {customerData.dateOfIncorporation ? moment(customerData?.dateOfIncorporation).format("DD-MM-YYYY"): "--"}                   
                      </div>
                    </Col>
                  )
                  :(
                  <Col xs={24} md={12} lg={12} xl={12} xxl={12} className="input-pl">
                    <Form.Item
                      className="input-label custom-forminput custom-label mb-0  c-pointer"
                      name="dateofIncorporation"
                      rules={[
                        {
                          required: true,
                          message: "Is required"
                        },
                        {
                          validator: validateContentRule
                        }
                      ]}
                      label={"Formation Date"}
                    >
                      <DatePicker
                        format={"DD/MM/YYYY"}
                        className="cust-input"
                        placeholder="Formation Date"
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>)}
                </Row>
              </div>
              <>
              <div className="box basic-info alert-info-custom">
              <Title
                className="adbook-head">
                Identification Details{" "}
              </Title>
                <Row gutter={24} className="pb-8">
                  <Col xs={24} md={24} lg={24} xl={24} xxl={24} >
                    <Form.Item
                      className="custom-forminput custom-label mb-0"
                      name="idNumber"
                      required
                      rules={[
                        {
                          whitespace: true,
                          message: "Is required"
                        },
                        {
                          validator:(_,val)=>{
                              return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,numberValidator:true,lableName:'id number'})
                          }
                      },
                      ]}
                      label={"Passport Number"}
                    >
                      <Input
                        className="cust-input "
                        placeholder={"Passport Number"}
                        disabled={customerData.idNumber}
                      />
                    </Form.Item>
                  </Col>        
                </Row>
              </div></>
                <div className="box basic-info alert-info-custom">
                <Title
                  className="adbook-head">
                  Address{" "}
                </Title>
                  <Row gutter={24} className="pb-8">
                  <Col xs={24} md={24} lg={24} xl={24} xxl={24}  >
                        <Form.Item
                          name={["address", "line1"]}
                          label="Address"
                            className="custom-forminput custom-label mb-0 mt-16"
                            required
                            rules={[
                              {
                              whitespace: true,
                              message: "Is required"
                            },
                            {
                              validator:(_,val)=>{
                                  return validateFeild({val:val,required: true,maxLength:250,validateContentRule:true,lableName:'address'})
                              }
                          },
                            ]}
                        >
                            <Input placeholder="Address" 
                            className="cust-input" 
                            disabled={customerData.address?.line1}
                            maxLength={1000}
                            />
                        </Form.Item>
                    </Col>
                    {props.currency == "USD" &&<Col xs={24} md={24} lg={24} xl={24} xxl={24}  >
                    <Form.Item
                            name={["address", "addressType"]}
                            label="Address Type"
                            className="custom-forminput custom-label mb-0 mt-16"
                            required
                            rules={[
                              {
                                  required: true,
                                  message: "Is required",
                              },
                              {
                                  whitespace: true,
                                  message: "Is required"
                              },
                              {
                              validator:validateContentRule,
                              },
                          ]}                         
                              >
                             <Select
                             placeholder="Address Type"
                           className="cust-input"
                           dropdownClassName="select-drpdwn"
                           disabled={customerData.address?.addressType}
                             >                           
                           {addressType.map((item,index)=>(
                             <Option key={index} value={item.name}>  
                             {item.name}                         
                              </Option>))}
                          </Select>
                        </Form.Item>
                    </Col>}

                    <Col xs={24} md={24} lg={24} xl={24} xxl={24} >
                      <Form.Item
                        name={["address", "country"]}
                        label="Country"
                        className="input-label custom-forminput custom-label mb-0 mt-16"
                        rules={[
                          {
                            required: true,
                            message: "Is required"
                          },
                          {
                            whitespace: true,
                            message: "Is required"
                          }
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Country"
                          className="cust-input select-crypto cust-adon mb-0 text-left c-pointer"
                          dropdownClassName="select-drpdwn"
                          onChange={(e) => handleCountry(e)}
                          bordered={false}
                          disabled={customerData.address?.country}
                        >
                          {country?.map((item, index) => (
                            <Option key={index} value={item.name}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {props.currency == "USD" && (
                     <> <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          name={["address", "state"]}
                          label="State"
                          className="input-label custom-label mt-16"
                          rules={[
                            {
                              required: true,
                              message: "Is required"
                            },
                            {
                              whitespace: true,
                              message: "Is required"
                            }
                          ]}
                        >
                          <Select
                            showSearch
                            dropdownClassName="select-drpdwn"
                            className="cust-input select-crypto cust-adon mb-0  c-pointer"
                            placeholder="State"
                            onChange={(e) => handleChange(e)}
                            disabled={customerData.address?.state}
                          >
                            {states?.map((item, index) => (
                              <Option key={index} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                          className="custom-forminput custom-label mb-0 mt-16"
                          name={["address", "postalCode"]}
                          required
                          rules={[
                            { required: true, message: "Is required", },
                            {
                              validator: (_, value) => {
                                  if (value && value.match(/\./g)) {
                                      return Promise.reject("Only numbers allowed");
                                  }
                                  return Promise.resolve();
                              }
                          }
                        ]}
                          label={"Postal Code"}
                        >
                        <NumberFormat
                                        customInput={Input}
                                        className="cust-input cust-select-arrow tfunds-inputgroup"
                                        placeholder={"Postal Code"}
                                        maxLength="6"
                                        displayType="input"
                                        allowNegative={false}
                                        disabled={customerData.address?.postalCode}
                                    />

                        </Form.Item>
                    </Col></>)}
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                            name={["address", "city"]}
                            label="City"
                            className="custom-forminput custom-label mb-0 mt-16"
                            required
                            rules={[
                              {
                                whitespace: true,
                                message: "Is required"
                              },
                              {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'city'})
                                }
                            },
                            ]}
                        >
                            <Input placeholder="City" 
                            className="cust-input" 
                            disabled={customerData.address?.city}
                            />
                        </Form.Item>
                    </Col>
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          className="custom-forminput custom-label mb-0 mt-16"
                          name="websiteUrls"
                          required
                          rules={[
                            {
                              whitespace: true,
                              message: "Is required"
                            },                          
                            {
                              validator:(_,val)=>{
                                  return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'website',websiteValidator:true})
                              }
                          },
                          ]}
                          label={"Website"}
                        >
                          <Input
                            className="cust-input"
                            placeholder={"Website"}
                            disabled={customerData?.websiteUrls}
                            maxLength={100}
                          />
                        </Form.Item>
                      </Col>
                  </Row>
                  {props.currency == "EUR" && (
                    <div>
                      <Row gutter={24} className="pb-8">                       
                      </Row>
                    </div>
                  )}
                </div>
              <div className="text-center mt-24 pb-8 create-account">
                <Form.Item>
                  {" "}
                  <Button
                    loading={accountloading}
                    type="submit"
                    htmlType="submit"
                    className="pop-btn px-24"
                    block
                  >
                    Confirm & Continue
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      )}
    </>
  );
};

const connectStateToProps = ({ userConfig, customerDetails }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    customerDetails: customerDetails.data
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};
export default connect(
  connectStateToProps,
  connectDispatchToProps
)(BusinessCreateAccount);
