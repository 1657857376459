import React, { Component } from "react";
import Button from "antd/lib/button";
import { connect } from "react-redux";
import GridList from "../grid.component";
import OnthegoFundTransfer from '../onthego.transfer';
import { setSelectedFeatureMenu } from "../../reducers/featuresReducer";
import { getFeaturePermissionsByKey } from "../../components/shared/permission/permissionService";

class Transfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            TransferGridUrl:process.env.REACT_APP_GRID_API +`Bank/Transactions`,
            sendEnable:this.props.showDrawer,
            permissions:{}
        };
    }
    gridRef = React.createRef();
    formRef = React.createRef();
    componentDidMount() {
        getFeaturePermissionsByKey(`send`);
        getFeaturePermissionsByKey(`transfer`);
        this.permissionsInterval = setInterval(this.loadPermissions, 200);
        if (this.props.match.params?.isPopup == "isPopup") {
            this.setState({ ...this.state, sendEnable: true })
        }

    }
    loadPermissions = () => {
        if(this.props?.updateAccess){
            this.props.history.push("/accessdenied");
          }
		if (this.props.sendPermissions) {
			this.props.dispatch(setSelectedFeatureMenu(this.props.sendPermissions?.featureId));
			clearInterval(this.permissionsInterval);
			let _permissions = {};
			for (let action of this.props.sendPermissions?.actions) {
				_permissions[action.permissionName] = action.values;
			}
			this.setState({ ...this.state, permissions: _permissions });
			if (!this.state.permissions?.Send) {
				this.props.history.push("/accessdenied");
			}
		}
        if (this.props.transferPermissions) {
            clearInterval(this.permissionsInterval);
            let _permissions = {};
            for (let action of this.props.transferPermissions?.actions) {
              _permissions[action.permissionName] = action.values;
            }
            this.setState({ ...this.state, transferPermissionName: _permissions });
          }
        
        
	}
	
    transferColumn = [
        {
            field: "createdAt",
            title: "Date",
            filter: true,
            isShowTime: true,
            filterType: "date",
            locked: true,
            width: 260,

        },

        {
            field: "baneficiaryName",
            title: "Name",
            filter: true,
            width: 220
        },

        {
            field: "currency",
            title: "Currency",
            filter: true,
            width: 180
        },

        {
            field: "credit",
            title: "Credit",
            filter: true,
            width: 200,
            dataType: "number",
			filterType: "numeric",
        },
        {
            field: "debit",
            title: "Debit",
            filter: true,
            width: 200,
            dataType: "number",
			filterType: "numeric",
        },

        {
            field: "status",
            title: "Status",
            filter: true,
            width: 180
        },
      
    ];

    addTransferDrawer = () => {
        this.setState({ ...this.state, sendEnable: true })
        
    }
  handleClose=()=>{
    this.setState({ ...this.state, sendEnable: false, selectedCurrency: null });
    this.gridRef?.current?.refreshGrid();
  }

    render() {
       
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center', marginBottom: '0px' }}>
                    <div></div>
                    {this.state.transferPermissionName?.Transfer && (
                    <div >
                        <Button className="pop-btn  px-24  c-pointer send-transfr-btn" onClick={() => this.addTransferDrawer()}>Transfer</Button>
                    </div>
                   )} 
                </div>
                <div className="pt-16 mb-28 custom-collapsetable">
                    {this.state.permissions?.Send && (
                    <div className="cust-list custom-grd-view transfer-grid" >
                        <GridList
                            className="address-clear cust-kendo-grid"
                            ref={this.gridRef}
                            columns={this.transferColumn}
                            key={this.state.TransferGridUrl}
                            url={this.state.TransferGridUrl}
                        />
                    </div>)}
                </div>
                {this.state.sendEnable &&
                    <OnthegoFundTransfer selectedCurrency={null} onClose={() =>this.handleClose()} />
                }
            </>
        );
    }
}
const connectStateToProps = ({ userConfig, transferData ,menuItems}) => {
    return {
        userConfig: userConfig.userProfileInfo, transferData,
        sendPermissions: menuItems?.featurePermissions?.send,
        transferPermissions: menuItems?.featurePermissions?.transfer,
        updateAccess: menuItems?.accessDenied
    };
};
export default connect(connectStateToProps)(Transfer);
