import { Component } from "react";
import { Form, Row, Col, Input } from "antd";
import { numberValidator, validateContentRule,validateFeild } from "../../../utils/custom.validator";
const { TextArea } = Input;
class DomesticTransfer extends Component {
    render() {
        return <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="accountNumber"
                    label={"Account Number"}
                    required
                    rules={[
                        {
                            required: true,
                            message: "Is required",
                        },
                        {
                            whitespace: true,
                            message: "Is required",
                        },
                        {
                            validator: (_, value) => {
                                if (
                                    value &&
                                    !/^[A-Za-z0-9]+$/.test(value)
                                ) {
                                    return Promise.reject(
                                        "Invalid Account Number"
                                    );
                                }
                                else if ( value && value.length > 50) {
                                    return Promise.reject("Invalid Account number");
                                } else {
                                    return validateContentRule(_, value);
                                }
                            },
                        }
                    ]}
                >
                    <Input
                        className="cust-input"
                        placeholder={"Account Number"}
                        maxLength={50}
                    />

                </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="abaRoutingCode"
                    label={"ABA Routing Code"}
                    required
                    rules={[
                        {
                            required: true,
                            message: "Is required",
                        },
                        {
                            whitespace: true,
                            message: "Is required",
                        },
                        {
                            validator: (_, value) => {
                                if (
                                    value &&
                                    !/^[A-Za-z0-9]+$/.test(value)
                                ) {
                                    return Promise.reject(
                                        "Invalid ABA Routing Code"
                                    );
                                }
                                else if ( value && value.length > 50) {
                                    return Promise.reject("Invalid ABA Routing code");
                                } else {
                                    return validateContentRule(_, value);
                                }
                            },
                        }
                    ]}
                >
                    <Input
                        className="cust-input"
                        placeholder={"ABA Routing Code"}
                    />

                </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="bankName"
                    label={"Bank Name"}
                    required
                    rules={[
                        {
                            required: true,
                            message: "Is required",
                        },
                        {
                            whitespace: true,
                            message: "Is required",
                        },
                        {
                            validator: (_, value) => {
                                if (
                                    value &&
                                    !/^[A-Za-z0-9_.-\s]+$/.test(value)
                                ) {
                                    return Promise.reject(
                                        "Invalid Bank Name"
                                    );
                                }
                                else if ( value && value.length > 50) {
                                    return Promise.reject("Invalid bank name");
                                }else {
                                    return validateContentRule(_, value);
                                }
                            },
                        }
                    ]}
                >
                    <Input
                        className="cust-input"
                        placeholder={"Bank Name"}
                        maxLength={100}
                    />

                </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="bankAddress1"
                    required
                    rules={[

                        {
                            whitespace: true,
                            message: "Is required",
                        },
                        {
                            validator:(_,val)=>{
                                return validateFeild({val:val,required: true,maxLength:250,validateContentRule:true,lableName:'bank address 1'})
                            }
                        },
                    ]}
                    label={
                        "Bank Address 1"
                    }
                >
                    <TextArea
                        placeholder={"Bank Address 1"}
                        className="cust-input cust-text-area address-book-cust"
                        autoSize={{ minRows: 1, maxRows: 1 }}
                        maxLength={1000}
                    ></TextArea>
                </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="bankAddress2"
                    rules={[
                        {
                            whitespace: true,
                            message: "Is required",
                        },
                        {
                            validator:(_,val)=>{
                                return validateFeild({val:val,required: false,maxLength:250,validateContentRule:true,lableName:'bank address 2'})
                            }
                        },
                    ]}
                    label={
                        "Bank Address 2"
                    }
                >
                    <TextArea
                        placeholder={"Bank Address 2"}
                        className="cust-input cust-text-area address-book-cust"
                        autoSize={{ minRows: 1, maxRows: 1 }}
                    
                    ></TextArea>
                </Form.Item>
            </Col>
            <Col
                      xs={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                    >
                      <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="reasonOfTransfer"
                        label={"Reason for Transfer"}
                        required
                        rules={[
                        {
                            validator: numberValidator,
                        },
                        {
                            whitespace: true,
                            message: "Is required",
                        },
                        
                    ]}
                      >
                        <Input
                          maxLength={20}
                          className="cust-input"
                          placeholder="Reason for Transfer"
                        />
                      </Form.Item>
                    </Col>
            
        </Row>
    }
}
export default DomesticTransfer;