export const ApiControllers ={
    cards:"Cards/",
    accounts:"Accounts/",
    bank:"Bank/",
    master:"Master/",
    wallets:"Wallets/",
    addressbook:"addressbook/",
    withdraw:"Withdraw/",
    common:"Common/",
    customers: "Customer/",
    deposit:"Deposit/",
    security:"Security/",

}