import React, { Component } from "react";
import {Tabs,Radio} from 'antd';
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Form from "antd/lib/form";
import List from "antd/lib/list";
import Button from "antd/lib/button";
import Alert from "antd/lib/alert";
import Modal from "antd/lib/modal";
import Tooltip from "antd/lib/tooltip";
import Image from "antd/lib/image";
import Select from "antd/lib/select";
import Drawer from "antd/lib/drawer";
import Input from "antd/lib/input";

import AddressDocumnet from "./document.upload";
import oops from '../../assets/images/oops.png'
import FiatAddress from "./fiat.address";
import alertIcon from '../../assets/images/pending-green.png';
import success from '../../assets/images/success.svg';
import NumberFormat from "react-number-format";
import { fetchPayees, fetchPastPayees, confirmTransaction,  saveWithdraw, saveCommision,
    validateAmount,getReasonforTransferDetails,getBanlLuData } from "./api";
import Loader from "../shared/loader";
import Search from "antd/lib/input/Search";
import Verifications from "./verification.component/verifications"
import { getVerificationFields } from "./verification.component/api"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import {getCountryStateLu,isErrorDispaly,getCustomerBankDetails} from '../../api/apiCalls'
import PhoneCodes from '../../utils/phoneCode.json'
import {validateContentRule } from '../../utils/custom.validator'
import { getFeaturePermissionsByKey } from "../../components/shared/permission/permissionService";
import { connect } from "react-redux";
import Paragraph from "antd/lib/typography/Paragraph";
import {getBankData} from '../../reducers/configReduser'
const { Option } = Select;
const { Text, Title } = Typography;

class OnthegoFundTransfer extends Component {
    enteramtForm = React.createRef();
    mainref = React.createRef();
    reasonForm = React.createRef();
    reviewForm = React.createRef();
    reviewScrool = React.createRef();
    amountScrool=React.createRef();
    state = {
        step: this.props.selectedCurrency ? "enteramount" : "selectcurrency",
        filterObj: [],
        selectedCurrency: this.props.selectedCurrency,
        addressOptions: { addressType: "myself", transferType: this.props.selectedCurrency === "EUR" ? "sepa" : "domestic" },
        isNewTransfer: false,
        amount: "",
        onTheGoObj: { amount: '', description: '' },
        reviewDetails: {},
        payees: [],
        amountbtnLoader: false,
        pastPayees: [],
        searchVal: "",
        errorMessage: null,
        codeDetails: { abaRoutingCode: "", swiftRouteBICNumber: "", reasionOfTransfer: "", documents: null },
        selectedPayee: {},
        selectedTab: "domestic",
        verifyData: null, isBtnLoading: false, reviewDetailsLoading: false,
        isVerificationEnable: true,
        isVarificationLoader: true,
        fiatWallets: [],
        showFuntransfer:true,
        selectedAccountId: this.props.selectedAccountId,
        phoneCode:null,
        transactionId : null,
        fileData: "",
        isShowGreyButton: false,
        popupLoader : false,
          downloadPopup:false,
          isDownLoadShow : false,
          permissions: {},
          fiatWalletsLoading:false,
          selectCurrencyData:null,
          commissionData:null,
          commissionLoader:false,
          effectiveType:false,
          selectedReasonforTransfer:null,
          reasonForTransferData:null,
          selectCurrencyLabelShow:false,
          isLoading:false,
          reasonForPayeeAddressDoc:null,
          BankLuData:[],
          selectedBankLuData:{},
          isShowFiatDropDown:false,
          bankLuLoader:false,
    }
    componentDidMount() {
        this.getReasonForTransferData();
        this.getBankData()
        this.getDetails()
        this.verificationCheck();
    }
    getDetails=(bankName)=>{
        if (!this.state.selectedCurrency && (bankName || this.state.selectedBankLuData?.bankName)) {
            this.setState({ ...this.state, fiatWalletsLoading: true });
            getCustomerBankDetails(bankName || this.state.selectedBankLuData?.bankName).then(res => {
                if (res.ok) {
                    let accountFilterList = res.data.filter((item)=>item.accountStatus=="Approved")
                    this.setState({ ...this.state, fiatWallets: accountFilterList, fiatWalletsLoading: false,isShowFiatDropDown:true });
                } else {
                    this.setState({ ...this.state, fiatWallets: [], fiatWalletsLoading: false });
                }
            });
        }
       }
    loadPermissions = () => {
        if (this.props.sendPermissions) {
          clearInterval(this.permissionsInterval);
          let _permissions = {};
          for (let action of this.props.sendPermissions?.actions) {
            _permissions[action.permissionName] = action.values;
          }
          this.setState({ ...this.state, permissions: _permissions });
        }
      }

    getCountryLU = async (countryName) => {
        let res = await getCountryStateLu();
        if (res.ok) {
          this.setState({...this.state,country:res.data})
          if (countryName) {
            let filteredCountry = res.data.filter((country) => {
              if (country.code) {
                return country?.code?.toLowerCase() == countryName?.toLowerCase();
              }
            });
            if (filteredCountry.length > 0) {
              this.setState({...this.state,state:filteredCountry[0].stateLookUp})
            }
          }
        }else{
            this.setState({...this.state,errorMessage:isErrorDispaly(res)})
        }
      };
       handleCountry = (state, stateLuc) => {
        this.setState({...this.state,state:[]})
        this.reviewForm.current.setFieldsValue({state:null})
        let selectCountry = stateLuc ? stateLuc : this.state.country;
        selectCountry?.map((item) => {
          if (state === item.code) {
            this.setState({...this.state,state:item?.stateLookUp})
          }
        });
      };
       handleChange = (code, stateLuc) => {
        let selectCountry = stateLuc ? stateLuc : this.state.country;
        if (code?.toLowerCase() === selectCountry.code?.toLowerCase()) {
           this.setState({...this.state,state:this.state.country?.stateLookUp})
        }
     };
    getPayees() {
        this.setState({ ...this.state, loading:true,addressLoader:false});
        this.chnageStep("addressselection")
        fetchPayees(this.state.selectedCurrency).then((response) => {
            if (response.ok) {
                this.setState({ ...this.state, filterObj: response.data, payees: response.data,loading:false });
            }
        });
        fetchPastPayees(this.state.selectedCurrency).then((response) => {
            if (response.ok) {
                this.setState({ ...this.state, pastPayees: response.data,loading:false });
            }
        });
    }
    verificationCheck = async () => {
        this.setState({ ...this.state, isVarificationLoader: true })
        const verfResponse = await getVerificationFields();
        let minVerifications = 0;
        if (verfResponse.ok) {
            for (let verifMethod in verfResponse.data) {
                if (["isEmailVerification", "isPhoneVerified", "twoFactorEnabled", "isLiveVerification"].includes(verifMethod) && verfResponse.data[verifMethod] === true) {
                    minVerifications = minVerifications + Number(process.env.REACT_APP_SUISSEBASE_MIN_VERIFICATIONS);
                }
            }
            if (minVerifications >= Number(process.env.REACT_APP_SUISSEBASE_MIN_VERIFICATIONS)) {
                this.setState({ ...this.state, isVarificationLoader: false, isVerificationEnable: true })
                getFeaturePermissionsByKey(`transfer`);
                this.permissionsInterval = setInterval(this.loadPermissions, 200);
            } else {
                this.setState({ ...this.state, isVarificationLoader: false, isVerificationEnable: false })
            }
        } else {
            this.setState({ ...this.state, isVarificationLoader: false, errorMessage: isErrorDispaly(verfResponse) })
        }
    }
    chnageStep = (step, values) => {
        this.mainref.current.scrollIntoView(0,0)
        this.setState({ ...this.state, step, onTheGoObj: values  });
        if (step === 'newtransfer' || step==="addressselection") {
            this.setState({ ...this.state, step, isNewTransfer: true, onTheGoObj: values });
        }
        if(step === "reviewdetails"){
            this.getCountryLU()
        }
    }
    handleForm=(item)=>{
        this.setState(
          {
            ...this.state,
            selectedPayee:item,
            isNewTransfer: false,
            effectiveType: false,
            newtransfer: false,
            selectCurrencyLabelShow:true
          },
        )
      }
    newtransfer=()=>{
        this.chnageStep("newtransfer")
    }
    goToAddressBook = () => {
        if(this.state.errorMessage ==null ){
        let _amt = this.enteramtForm.current.getFieldsValue().amount;
        _amt =typeof _amt=="string" ? _amt?.replace(/,/g, '') : _amt;
        if (_amt > 0) {
          this.setState(
            {
              ...this.state,
              isNewTransfer: false,
              amount: _amt,
              onTheGoObj: this.enteramtForm.current.getFieldsValue(),
              effectiveType:false,
              addressLoader:true,amountbtnLoader:true
            },
            () => {
              this.enteramtForm.current?.validateFields()
                .then(() => this.handleContinue()
                )
            },
          )
        } else {
          if (!_amt) {
            this.enteramtForm.current.validateFields()
          } else if(_amt.charAt(0).includes(".")) {
            this.setState({
              ...this.state,
              effectiveType:false,
              errorMessage: null,
              addressLoader:false,
              amountbtnLoader:false 
            })
            this.amountScrool.current.scrollIntoView(0,0);
          }else{
            this.setState({
              ...this.state,
              effectiveType:false,
              errorMessage: 'Amount must be greater than zero',
              addressLoader:false,
              commissionLoader:false 
            })
            this.amountScrool.current.scrollIntoView(0,0);
          }
        }
    }
      }
    amountNext = (values) => {
        let _amt = values.amount;
        _amt = _amt.replace(/,/g, "");
        if(_amt>0){
            if(this.state?.errorMessage ==null ){
                this.setState({ ...this.state, amount: _amt,effectiveType:false }, () => this.validateAmt(_amt, "reviewdetails", values, "newtransferLoader"));
            }
        }else{
            if(!_amt){
                this.setState({ ...this.state, errorMessage:''});
            }else{
            this.setState({ ...this.state, errorMessage:'Amount must be greater than zero'});}
            this.amountScrool.current.scrollIntoView(0,0);
        }
    }
    handleSearch = ({ target: { value: val } }) => {
        if (val) {
            const filterObj = this.state.payees.filter(item => item.name.toLowerCase().includes(val.toLowerCase()));
            this.setState({ ...this.state, filterObj, searchVal: val });
        }
        else
            this.setState({ ...this.state, filterObj: this.state.payees,searchVal: val });
    }
    saveWithdrawdata = async () => {
        this.reviewForm.current?.validateFields().then(async () => {
            const fieldValues = this.reviewForm.current?.getFieldsValue();
            if (this.state.verifyData?.verifyData) {
                if (this.state.verifyData.verifyData.isPhoneVerified) {
                    if (!this.state.verifyData.isPhoneVerification) {
                        this.setState({
                            ...this.state,
                            errorMessage: "Please verify phone verification code"
                        });
                        this.reviewScrool.current.scrollIntoView()
                        return;
                    }
                }
                if (this.state.verifyData.verifyData.isEmailVerification) {
                    if (!this.state.verifyData.isEmailVerification) {
                        this.setState({
                            ...this.state,
                            errorMessage: "Please verify  email verification code"
                        });
                        this.reviewScrool.current.scrollIntoView()
                        return;
                    }
                }
                if (this.state.verifyData.verifyData.twoFactorEnabled) {
                    if (!this.state.verifyData.isAuthenticatorVerification) {
                        this.setState({
                            ...this.state,
                            errorMessage: "Please verify authenticator code"
                        });
                        this.reviewScrool.current.scrollIntoView()
                        return;
                    }
                }
                if (
                  this.state.verifyData.verifyData.isPhoneVerified === "" &&
                  this.state.verifyData.verifyData.isEmailVerification === "" &&
                  this.state.verifyData.verifyData.twoFactorEnabled === ""
                ) {
                    this.setState({
                        ...this.state,
                        errorMessage:
                            "Without Verifications you can't send. Please select  verifications from security section",
                    });
                    this.reviewScrool.current.scrollIntoView()
                    return
                }
            } 
        this.setState({ ...this.state, isBtnLoading: true })

        if (this.state.reviewDetails) {
            let obj = Object.assign({}, this.state.reviewDetails);
          
            let saveOBJ={...obj,
                "payeeId": obj.payeeId,
                "currency": obj.walletCode,
                "phoneNo": (this.state.phoneCode + fieldValues.phoneNo) || obj.phoneNo,
                "email": fieldValues.email || obj.email,
                "amount": obj.totalValue,
                "description": obj.customerRemarks,
                "docRepositories": this.state.reasonForPayeeAddressDoc,
                "country" : fieldValues.country || obj.country,
                "state": fieldValues.state || obj.state,
                "city": fieldValues.city || obj.city,
                "postalCode": fieldValues.postalCode || obj.zipcode,
                "addressType" :this.state.addressOptions?.addressType,
                "routingNumber" : obj.routingNumber,
                "provider":this.props.match?.params?.isBank || this.state.selectedBankLuData?.bankName,
                "Ticks":obj?.wdTimeStamp
              }
              const saveRes = await saveWithdraw(saveOBJ,this.props.match?.params?.isBank || this.state.selectedBankLuData?.bankName);
            if (saveRes.ok) {
               this.setState({transactionId : saveRes.data.transactionId}) 
               this.props?.getBankDetails(this.props.match?.params?.isBank)
                if(this.props?.refreshDashBoard){this.props?.refreshDashBoard(true)}
                this.chnageStep("successpage")
                // this.chnageStep(this.state.isNewTransfer ? "declaration" : "successpage")
            } else {
                this.setState({
                    ...this.state,
                    errorMessage: isErrorDispaly(saveRes), isBtnLoading: false,addressLoader:false
                });
                this.reviewScrool.current.scrollIntoView(0,0)
            }
        }
        }).catch(error => {
            this.setState({ ...this.state, isBtnLoading: false ,addressLoader:false})
        });
    }

    isAllVerificationsFullfilled = (obj) => {
        const vdata=this.state.verifyData ||{}
        const vDetails=Object.keys(vdata).length===0?obj:this.state.verifyData;
        let _verficationDetails = { ...vDetails,...obj };
        let _verificationCount = 0;
        let _currentVerificationCount = 0;
        for (let key in _verficationDetails) {
          if (["isPhoneVerification", "isEmailVerification", "isAuthenticatorVerification"].includes(key) && _verficationDetails[key]) {
            _currentVerificationCount++;
          }
        }
    
        for (let key in _verficationDetails?.verifyData) {
          if (["isPhoneVerified", "isEmailVerification", "twoFactorEnabled"].includes(key) && _verficationDetails?.verifyData[key]) {
            _verificationCount++;
          }
        }
        return _verificationCount === _currentVerificationCount;
      }
    changesVerification = (obj) => {
        this.setState({ ...this.state, isShowGreyButton: this.isAllVerificationsFullfilled(obj), verifyData: {...this.state.verifyData,...obj} })
      }    
   
    onReviewDetailsLoading = (val) => {
        this.setState({ ...this.state, reviewDetailsLoading: val })
    }
    saveCommisions=async()=>{
        let _amt = this.enteramtForm.current.getFieldsValue().amount;
        _amt =typeof _amt=="string" ? _amt?.replace(/,/g, '') : _amt;
        const obj = {
            "payeeId": this.state.reviewDetails?.id,
             "reasonOfTransfer": "",
             "amount":_amt, 
             "accountId" :this.state.selectedAccountId,
        }
        this.setState({ ...this.state, errorMessage: null,commissionData:null,commissionLoader:false,amount:_amt,isLoading:true });
        if(_amt>0){
        const res = await saveCommision(obj);
        if (res.ok) {
            this.setState({ ...this.state,commissionData:res.data, errorMessage: null,commissionLoader:true,isLoading:false,effectiveType:false});
        } else {
            this.setState({ ...this.state, errorMessage: isErrorDispaly(res),commissionData:null,commissionLoader:false,isLoading:false })
        }
    }
    else {
        this.setState({ ...this.state, errorMessage:null,commissionData:null,commissionLoader:false,isLoading:false })
    }
    }
    validateAmt = async (amt, step, values, loader) => {
        const obj = {
            CustomerId : this.props.userProfile?.id,
            Amount : amt,
            Currency : this.state.selectedCurrency,
            provider:this.state.selectedBankLuData?.bankName || this.props.match?.params?.isBank
        }
        this.setState({ ...this.state, [loader]: true,amountbtnLoader:true , errorMessage: null,addressLoader:true });
        const res = await validateAmount(obj);
        if (res.ok) {
            const response = await confirmTransaction({ 
                payeeId: this.state.selectedPayee.id, reasonOfTransfer: this.state?.selectedPayee?.reasonOfTransfer,
                 amount: this.state.amount,accountId :this.state.selectedAccountId,transferOthers:this.state?.selectedPayee?.transferOthers
            });
            if(response.ok){
              this.setState({ ...this.state, [loader]: false, 
                addressLoader:false,errorMessage: null,reviewDetails: response.data });
             this.chnageStep(step, values);
            }else{
              this.setState({ ...this.state, [loader]: false, errorMessage:isErrorDispaly(response),addressLoader:false })
            }
        } else {
            this.setState({ ...this.state, [loader]: false, errorMessage: isErrorDispaly(res),amountbtnLoader:false,addressLoader:false })
        }
    }
    handledwnClose = () =>{
        this.setState({...this.state,downloadPopup:false});
    }

      handleCurrencyselect=(item)=>{
       this.setState({ ...this.state,selectCurrencyData:item, selectedCurrency: item.currency, selectedAccountId:item.id }, () => {
        if(item?.availableBalance){
            this.chnageStep("enteramount");
        }else{
            this.setState({...this.state,errorMessage:"Insufficient balance"})
        }
    })
      }
      handleContinue= async () => {
        
            if (!["myself", "1stparty", 'ownbusiness'].includes( this.state.selectedPayee.addressType?.toLowerCase())) {
                this.setState({ ...this.state, addressOptions: { ...this.state.addressOptions, addressType: this.state.selectedPayee.addressType }, codeDetails: { ...this.state.codeDetails, } }, () => this.chnageStep("reasonfortransfer"));
            } else {
                const fieldValues = this.reasonForm?.current?.getFieldsValue();
                this.setState({ ...this.state, loading: true, errorMessage: null,amountbtnLoader:true, codeDetails: { ...this.state.codeDetails, } ,addressLoader:true});
                const res = await confirmTransaction({ payeeId: this.state.selectedPayee.id, reasonOfTransfer: fieldValues?.reasonOfTransfer, amount: this.state.amount,accountId :this.state.selectedAccountId });
                if (res.ok) {
                    this.setState({ ...this.state, reviewDetails: res.data, loading: false,amountbtnLoader:false,addressLoader:false }, 
                        () => {this.chnageStep("reviewdetails") },
                        );
                } else {
                    this.setState({ ...this.state, loading: false, errorMessage: isErrorDispaly(res),amountbtnLoader:false,addressLoader:false });
                }
            }
        }
        verificationsData=(data)=>{
            if(data?.isLiveVerification && !data?.twoFactorEnabled && !data?.isEmailVerification && !data?.isPhoneVerified ){
              this.setState({ ...this.state, 
                isShowGreyButton: true });
            }
          }
          feeChange=()=>{
            if(this.state.effectiveType){
              this.setState({...this.state,effectiveType:false})
            }else{
              this.setState({...this.state,effectiveType:true})
            }
          }
          handleReasonTrnsfer=(e)=>{
            this.setState({...this.state,selectedReasonforTransfer:e})
            this.reasonForm.current.setFieldsValue({transferOthers:null})
        }
        getReasonForTransferData=async()=>{
            let res = await getReasonforTransferDetails();
            if(res.ok){
                this.setState({...this.state,reasonForTransferData:res.data,errorMessage:null})
            }else{
                this.setState({...this.state,errorMessage: isErrorDispaly(res),})
               
            }
          }
          newAddressBook = () => {
            this.setState(
              {
                ...this.state,
                isNewTransfer: false,
                effectiveType: false,
                newtransfer: true,
              },
            )
            this.chnageStep("newtransfer");
            
          }
          handleClose=()=>{
            this.setState({ ...this.state, showFuntransfer: false });
            this.props.onClose();
           
          }
          addressDoc=(data)=>{
            this.setState({...this.state,reasonForPayeeAddressDoc:data});
          }
          handleTabChange = (key) => {
            this.setState({ ...this.state, selectedTab: key})
        }
        submitHandler = async(e) => {
                const fieldValues = this.reasonForm?.current?.getFieldsValue();
                    this.setState({ ...this.state, loading: true, errorMessage: null });
                    const res = await confirmTransaction({ payeeId: this.state.selectedPayee.id, reasonOfTransfer: fieldValues.reasonOfTransfer, transferOthers:fieldValues?.transferOthers, amount: this.state.amount, docRepositories:this.state.codeDetails?.documents,accountId :this.state.selectedAccountId  });
                    if (res.ok) {
                        this.setState({ ...this.state, reviewDetails: res.data, loading: false,reasonForPayeeAddressDoc:this.state.codeDetails?.documents }, () => { this.chnageStep("reviewdetails") });
                    } else {
                        this.setState({ ...this.state, codeDetails: { ...this.state.codeDetails, ...fieldValues }, loading: false, errorMessage: isErrorDispaly(res)});
                    }


          }
          checkpastReciapient=async(item)=>{
            this.setState(
                {
                  ...this.state,
                  selectedPayee:item,
                  isNewTransfer: false,
                  effectiveType: false,
                  newtransfer: false,
                  selectCurrencyLabelShow:true
                },
              )
          }
          goback=()=>{
            this.setState({...this.state,addressLoader:false})
            this.chnageStep("selectcurrency")
            setTimeout(() => {
               this.getCustomerBankDetail()
            }, 200);
           
          }
          getBankData=async()=>{
            this.state.bankLuLoader=true
            let res=await getBanlLuData();
            if(res.ok){
                this.setState({...this.state,BankLuData:res.data,bankLuLoader:false});
            }else{
                this.setState({ ...this.state,errorMessage: isErrorDispaly(res),bankLuLoader:false})
            }
          }
          handleBankLu=(item)=>{
            this.setState({...this.state,selectedBankLuData:item,errorMessage:null,selectedCurrency:null},()=>{
                this.getDetails(item?.bankName)
            })
          }
    renderStep = () => {
        const { filterObj, pastPayees, isVarificationLoader, isVerificationEnable,isShowGreyButton,selectCurrencyData,commissionData } = this.state;
        const steps = {
            selectcurrency: (<React.Fragment>
                 {this.state.errorMessage && <Alert type="error" description={this.state.errorMessage} showIcon />}
                {!isVerificationEnable &&
                            <Alert
                                message="Verification alert !"
                                description={<Text>Without verifications you can't send. Please select verifications from <a onClick={() => {
                                    localStorage.setItem("myurl",`${process.env.REACT_App_EXCHANGE_URL}/userprofile/2`)
                                    window.open(`${process.env.REACT_App_EXCHANGE_URL}/userprofile/2`,"_self")                                
                                }}>security section</a></Text>}
                                type="warning"
                                showIcon
                                closable={false}
                            />
                        }
                        {this.state?.bankLuLoader && <Loader/>}
                       {(isVerificationEnable && this.state.permissions.Send) && <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="payeeAccountCountry"
                        label={
                            'Select Your Bank'
                        }
                    >
                        <Select
                            // showSearch
                            placeholder={"Select Your Bank"}
                            className="cust-input select-crypto cust-adon mb-0  c-pointer"
                            dropdownClassName="select-drpdwn"
                            bordered={false}
                            onSelect={(value, option) => this.handleBankLu(option.data)}
                            // onChange={(item)=>this.handleBankLu(item)}
                        >
                          {this.state.BankLuData?.map((item, indx) => (
                            <Option key={indx} value={item.bankName} data={item}>
                            {item.bankName}
                            </Option>
                             ))}
                        </Select>
                       </Form.Item>}
                {this.state?.fiatWalletsLoading && <Loader/>}
                {(isVerificationEnable && this.state.permissions.Send &&!this.state.fiatWalletsLoading && !this.state?.bankLuLoader) && 
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.fiatWallets}
                    className="crypto-list auto-scroll wallet-list "
                    loading={this.state.fiatWalletsLoading}
                    locale={{
                        emptyText: <>
                        {/* <Alert
                        message="Accounts Alert !"
                        description={<Text> Accounts are not found / pending status <a onClick={() => {
                            this.props.history.push('/dashboard')
                        }}>click here to verify</a>.</Text>}
                        type="warning"
                        className="text-left"
                        showIcon
                        closable={false}
                    /> */}
                    {/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                            "No Data Found"
                        } /> */}
                        
                        </>
                    }}
                    renderItem={item => {
                        if(item.accountStatus=="Approved" ){
                            return <List.Item onClick={()=>this.handleCurrencyselect(item)}>
                            <Link>
                                <List.Item.Meta
                                    avatar={<Image preview={false} src={item.imagePath} />}
                                    title={<div className="wallet-title">{item.currency}</div>}
                                />
                                <><div className="text-right coin-typo">
                                {item.availableBalance !== null && <NumberFormat value={item.availableBalance} className="text-white-30 fw-600" displayType={'text'} thousandSeparator={true} prefix={item.currency == 'USD' ? '$' : '€'} />}
                                </div></>
                            </Link>
                        </List.Item>
                        }
                    }}
                />}
            </React.Fragment>),
            enteramount: <>
               
                {isVarificationLoader && <Loader />}
                <div ref={this.amountScrool}></div>
                {!isVarificationLoader && 
                    <Form
                        autoComplete="off"
                        initialValues={{ amount: "" }}
                        ref={this.enteramtForm}
                        onFinish={this.amountNext}
                        scrollToFirstError  
                    >
                        {!isVerificationEnable &&
                            <Alert
                                message="Verification alert !"
                                description={<Text>Without verifications you can't send. Please select verifications from <a onClick={() => {
                                    localStorage.setItem("myurl",`${process.env.REACT_App_EXCHANGE_URL}/userprofile/2`)
                                    window.open(`${process.env.REACT_App_EXCHANGE_URL}/userprofile/2`,"_self")
                                }}>security section</a></Text>}
                                type="warning"
                                showIcon
                                closable={false}
                            />
                        }
                        {this.state.errorMessage && <Alert type="error" description={this.state.errorMessage} showIcon />}
                        {(isVerificationEnable && this.state.permissions.Send) &&
                         <Form onFinish={this.newtransfer}>
                         <Row gutter={[16, 16]}>
                               <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                                 <div className="summarybal total-amount">
                                 {this.state.selectedCurrency} {" "}
                                   <NumberFormat
                                     decimalScale={2}
                                     placeholder={"Enter Amount"}
                                     thousandSeparator={true} displayType={"text"}
                                     disabled
                                     value={selectCurrencyData?.availableBalance ||this.props?.availableBalance}
                                   />
                                 </div>
                               </Col>
                               </Row>
                           <Row gutter={[16]} className="send-drawerbtn">
           
                               <Col xs={24} md={12} lg={12} xl={12} xxl={12} className="mobile-btn-pd">
                                   <Form.Item className="text-center">
                                       <Button
                                           htmlType="submit"
                                           size="large"
                                           className="newtransfer-card"
                                           onClick={this.newAddressBook}
                                       >
                                           New Transfer
                                       </Button>
                                   </Form.Item>
                               </Col>
                               <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                                   <Form.Item className="text-center">
                                       <Button
                                           htmlType="button"
                                           size="large"
                                           className="newtransfer-card"
                                           loading={this.state.addressLoader}
                                           disabled={this.state.newtransferLoader}
                                           onClick={()=>this.getPayees()}
                                       >
                                           Address Book
                                       </Button>
                                   </Form.Item>
                               </Col>
                           </Row>
                           </Form>}
                    </Form>}</>,
            addressselection: <React.Fragment>
                {this.state.errorMessage && <Alert type="error" description={this.state.errorMessage} showIcon />}
               { !this.state.selectCurrencyLabelShow&&<><div className="" style={{textAlign:'left'}}>
                    <text Paragraph
                        className='sub-abovesearch px-4 ' >Who are you sending money to?</text>
                </div>
                {this.state.selectedTab != 2 && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Search placeholder="Search For Payee" value={this.state.searchVal} prefix={<span className="icon lg search-angle drawer-search" />} onChange={this.handleSearch} size="middle" bordered={false} className="cust-search" />
                </Col>}
                {this.state?.loading && <Loader />}
                {(!this.state.loading) && <>
                    <Tabs className="cust-tabs-fait"
                    activeKey={this.selectedTab}
                    onChange={this.handleTabChange}>
                    <Tabs.TabPane tab="Address Book"  key={1} className=""  component={Radio}>
                    <ul style={{ listStyle: 'none', paddingLeft: 0, }} className="addCryptoList paste-recept-style mobile-scroll adbook-scroll">
                        {(filterObj.length > 0) && filterObj?.map((item, idx) =>
                            <>{<Row className="fund-border c-pointer " onClick={()=>this.handleForm(item)}>
                                <Col xs={4} md={2} lg={2} xl={3} xxl={3} className=""><div class="fund-circle text-white">{item?.name.charAt(0).toUpperCase()}</div></Col>
                                <Col xs={18} md={22} lg={22} xl={19} xxl={19} className="small-text-align">
                                    <label className="address-name c-pointer">
                                        <strong>{item.name}
                                        </strong>
                                    </label>
                                    {item.accountNumber && <div><Text className="address-subtext">{this.state.selectedCurrency} account ending with {item.accountNumber?.substr(item.accountNumber.length - 4)}</Text></div>}
                                </Col>
                            </Row>}</>
                        )}
                        {(!filterObj.length > 0) && <div className="success-pop text-center declaration-content">
                            <img src={oops} className="confirm-icon nodata-image" style={{ marginBottom: '10px' }} alt="Confirm" />
                            <h1 className="success-title oops-title" > {"OOPS"}</h1>
                            <p className="successsubtext custom-height"> {"No addresses available"} </p>
                            <a onClick={() => this.chnageStep("newtransfer")} className="nodata-text">Click here to make new transfer</a>
                        </div>}
                    </ul>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Past Recipients"  key={2} className=""  component={Radio}>
                    <ul style={{ listStyle: 'none', paddingLeft: 0, }} className="addCryptoList paste-recept-style mobile-scroll adbook-scroll">
                        {(pastPayees.length > 0) && pastPayees?.map((item, idx) =>
                            <Row className="fund-border c-pointer" key={idx} onClick={async () => {this.checkpastReciapient(item)
                            }}>
                                <Col xs={4} md={2} lg={2} xl={3} xxl={3} className=""><div class="fund-circle text-white">{item?.name.charAt(0).toUpperCase()}</div></Col>
                                <Col xs={18} md={22} lg={22} xl={19} xxl={19} className=" small-text-align">
                                    <label className="address-name c-pointer">
                                        <strong>{item.name}
                                        </strong>
                                    </label>
                                    <div><Text className="address-subtext">{this.state.selectedCurrency} account ending with {item.accountNumber?.substr(item.accountNumber?.length - 4)}</Text></div>
                                </Col>
                            </Row>

                        )}
                        {(!pastPayees.length > 0) && <div className="success-pop text-center declaration-content">
                            <img src={oops} className="confirm-icon nodata-image" style={{ marginBottom: '10px' }} alt="Confirm" />
                            <h1 className="success-title oops-title" > {"OOPS"}</h1>
                            <p className="successsubtext custom-height"> {'You have no past recipients'} </p>
                        </div>}
                    </ul>
                    </Tabs.TabPane>
                    </Tabs>
                </>}</>}
                {this.state.selectCurrencyLabelShow && <>
                    <div ref={this.amountScrool}></div>
                    <Form
                        autoComplete="off"
                        initialValues={{ amount: "" }}
                        ref={this.enteramtForm}
                        onFinish={this.amountNext}
                        scrollToFirstError
                    >
                        <Row gutter={[16, 16]} className="align-center send-crypto-err">
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="p-relative">
                                <Form.Item
                                    className={`custom-forminput custom-label fund-transfer-input cust-send-amountfield send-fiat-input`}
                                    name="amount"
                                    label={"Withdrawal Amount"}
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Is required',
                                        },
                                        {
                                            validator: (_, value) => {
                                                const reg = /.*[0-9].*/g;
                                                if (value && !reg.test(value)) {
                                                    return Promise.reject("Invalid amount");
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                >
                                    <NumberFormat
                                        customInput={Input}
                                        className="cust-input cust-select-arrow tfunds-inputgroup"
                                        placeholder={"Enter Amount"}
                                        maxLength="13"
                                        decimalScale={2}
                                        displayType="input"
                                        allowNegative={false}
                                        thousandSeparator={","}
                                        addonBefore={
                                            <span  className="btn-space">{this.state.selectedCurrency} ({selectCurrencyData?.availableBalance?.toLocaleString() ||this.props?.availableBalance?.toLocaleString()})</span>
                                              }
                                        onValueChange={() => {
                                            this.setState({ ...this.state, amount: this.enteramtForm.current?.getFieldsValue().amount, errorMessage: null, },)
                                        }}
                                        onBlur={(e) => this.saveCommisions(e)}
                                    />
                                </Form.Item>
                            </Col>
                            {(this.state.detailstype || commissionData || this.enteramtForm.current?.getFieldsValue().amount !== "") && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                                {this.state.isLoading && <Loader />} 
                                   {commissionData &&!this.state.isLoading && <div className="cust-summary-new">

                                        <div>
                                            <> <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                                <div className="summary-liststyle">Withdrawal Amount</div>
                                                <div className="summarybal"><NumberFormat
                                                    decimalScale={2}
                                                    value={`${this.state?.commissionData?.showAmount}`}
                                                    thousandSeparator={true} displayType={"text"} /> {`${this.state?.selectedCurrency}`}</div>
                                            </div>
                                                <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                                    <div className="summary-liststyle Effective-Fees" onClick={() => this.feeChange()}><span>Effective Fees</span><span className={`${this.state.effectiveType ? "icon lg up-arrow" : "icon lg down-arrow"}`}></span></div>
                                                    <div className="summarybal"><NumberFormat
                                                        value={`${(commissionData?.effectiveFee)}`}
                                                        thousandSeparator={true} displayType={"text"} /> {`${this.state?.selectedCurrency}`}</div>
                                                </div>
                                                {this.state.effectiveType && <>  <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                                    <div className="summary-liststyle">Fees</div>
                                                    <div className="summarybal"><NumberFormat
                                                        value={`${(commissionData?.totalFee)}`}
                                                        thousandSeparator={true} displayType={"text"} /> {`${this.state?.selectedCurrency}`}</div>
                                                </div>
                                                </>}
                                                {(this.state.effectiveType  && commissionData?.tierDiscount !=0 )&& <> <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                                    <div className="summary-liststyle">Tier Discount</div>
                                                    <div className="summarybal"><NumberFormat
                                                        value={`${(commissionData?.tierDiscount)}`}
                                                        thousandSeparator={true} displayType={"text"} /> {`${this.state?.selectedCurrency}`}</div>
                                                </div>
                                                </>}
                                                <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                                    <div className="summary-liststyle">How Much Beneficiary Will Receive</div>
                                                    <div className="summarybal whitespace-nowrap"><NumberFormat
                                                        value={`${commissionData?.amount}`}
                                                        thousandSeparator={true} displayType={"text"} /> {`${this.state?.selectedCurrency}`}</div>
                                                </div></>
                                        </div>
                                    </div>}
                            </Col>}
                        </Row>
                        <Row gutter={[16, 16]} className="send-drawerbtn">
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="mobile-viewbtns mobile-btn-pd">
                                <Form.Item>
                                    {this.state.newtransfer &&
                                        <Button
                                            htmlType={"submit"}
                                            loading={this.state.addressLoader}
                                            size="large"
                                            block
                                            className="pop-btn custom-send cust-disabled"
                                        >
                                            Continue
                                        </Button>
                                    }
                                    {!this.state.newtransfer &&
                                        <Button
                                            htmlType={"button"}
                                            loading={this.state.addressLoader}
                                            onClick={this.goToAddressBook}
                                            size="large"
                                            block
                                            className="pop-btn custom-send cust-disabled"
                                        >
                                            Continue
                                        </Button>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </>}

            </React.Fragment>,
            reasonfortransfer: <React.Fragment>
                <div className="mb-16" >
                    <text Paragraph
                        className="adbook-head text-left" >Transfer Details</text>
                </div>
                <Form
                    initialValues={this.state.codeDetails}
                    ref={this.reasonForm}
                    onFinish={this.submitHandler}

                >
                    {this.state.errorMessage && <Alert type="error" description={this.state.errorMessage} showIcon />}
                    <React.Fragment><Row gutter={[16, 16]} style={{marginBottom:16}}>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                        className="custom-forminput custom-label"
                        name="reasonOfTransfer"
                        required
                        rules={[
                            {
                                required: true,
                                message: "Is required",
                            },
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                            {
                                validator: validateContentRule,
                            },
                        ]}
                        label={
                            "Reason For Transfer"
                        }
                    >
                        <Select
                            className="cust-input"
                            maxLength={100}
                            placeholder={"Reason For Transfer"}
                            optionFilterProp="children"
                            onChange={(e) => this.handleReasonTrnsfer(e)}
                        >
                            {this.state.reasonForTransferData?.map((item, idx) => (
                                <Option key={idx} value={item.name}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                        {this.state.selectedReasonforTransfer=="Others" && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                            className=" mb-8 text-white-50 custom-forminput custom-label pt-8 sc-error"
                            name="transferOthers"
                            required
                            rules={[
                                {whitespace: true,
                                message: "Is required",
                                },
                                {
                                required: true,
                                message: "Is required",
                                },
                                {
                                validator: validateContentRule,
                            },
                            ]}
                            >
                            <Input
                                className="cust-input"
                                maxLength={100}
                                placeholder="Please specify:"
                            />
                            </Form.Item>
                      </Col>}
                    </Row>
                        <AddressDocumnet documents={this.state.codeDetails.documents} onDocumentsChange={(docs) => {
                            let { documents } = this.state.codeDetails;
                            documents = docs;
                            this.setState({ ...this.state, codeDetails: { ...this.state.codeDetails, documents } })
                        }} title={"Please Upload Supporting Documents To Justify Your Transfer Request. E.G. Invoice, Agreements "} type={"reasonPayee"}/>
                    </React.Fragment>
                  
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item className="text-right">
                                <Button
                                    htmlType="submit"
                                    size="large"
                                    className="pop-btn mb-36"
                                    loading={this.state.loading}
                                    block
                                >
                                    Next
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>,
            reviewdetails: <React.Fragment>
                <div ref={this.reviewScrool}></div>
                <div className="text-center">
                <div Paragraph
            className='drawer-maintitle text-center'>Review Details Of Transfer</div>
                </div>
                   
                      
                        {this.state.errorMessage && <Alert type="error" showIcon closable={false} description={this.state.errorMessage} className="mb-0 mt-8" />}
                                <div className="d-flex  justify-content" style={{ alignItems: 'baseline' }}>
                                    <div className="adbook-head" >Transfer details</div>
                  </div>
                  <div className="cust-summary-new">
                  <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                    <div className="summary-liststyle">Withdrawal amount</div>
                                    <div className="summarybal"><NumberFormat
                                    decimalScale={2}
                                        value={`${(this.state.reviewDetails?.requestedAmount)}`}
                                        thousandSeparator={true} displayType={"text"} /> {`${this.state.reviewDetails?.walletCode}`}</div>
                  </div>
                <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                    <div className="summary-liststyle 
                Effective-Fees"  onClick={()=>this.feeChange()}><span>Effective Fees</span><span className={`${this.state.effectiveType ? "icon lg up-arrow" : "icon lg down-arrow"}`}></span></div>
                                    <div className="summarybal"><NumberFormat
                                        value={`${(this.state.reviewDetails?.effectiveFee)}`}
                                        thousandSeparator={true} displayType={"text"} decimalScale={2} /> {`${this.state.reviewDetails?.walletCode}`}</div>
                  </div>  
                 {this.state.effectiveType && <> <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                    <div className="summary-liststyle">Fees</div>
                                    <div className="summarybal"><NumberFormat
                                        value={`${(this.state.reviewDetails?.totalFee)}`}

                                        thousandSeparator={true} displayType={"text"} /> {`${this.state.reviewDetails?.walletCode}`}</div>
                  </div>
                  </>}
                  {(this.state.effectiveType && this.state.reviewDetails?.tierDiscount !=0) && <> <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                    <div className="summary-liststyle">Tier Discount</div>
                                    <div className="summarybal"><NumberFormat
                                        value={`${(this.state.reviewDetails?.tierDiscount)}`}

                                        thousandSeparator={true} displayType={"text"} /> {`${this.state.reviewDetails?.walletCode}`}</div>
                  </div>
                  </>}
                  <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                    <div className="summary-liststyle">How much Beneficiary will receive</div>
                    <div className="summarybal whitespace-nowrap">
                    <NumberFormat
                                            value={`${this.state?.reviewDetails?.amount}`}
                                            thousandSeparator={true} displayType={"text"} decimalScale={2} /> {`${this.state.reviewDetails?.walletCode}`}</div>
                  </div>
                

                  </div>
                 
                                <div className="d-flex  justify-content">
                                    <div className="adbook-head">Recipient details</div>
                  </div>
                  <div className="cust-summary-new kpi-List sendfiat-summarystyle">
                <div className="kpi-divstyle" >
                                    <div className="kpi-label">Whitelist Name </div>
                                   <div> <Text className="kpi-val">{this.state.reviewDetails?.favouriteName}</Text></div>
                  </div>
               
                {this.state.reviewDetails?.name &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">Beneficiary Name</div>
                                   <div> <Text className="kpi-val">{this.state.reviewDetails?.name}</Text></div>
                                </div>
                           }
                {this.state.reviewDetails?.firstName && 
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">First Name</div>
                                   <div> <Text className="kpi-val">{this.state.reviewDetails?.firstName}</Text></div>
                                </div>
                          }
                {this.state.reviewDetails?.lastName && 
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">Last Name</div>
                                   <div> <Text className="kpi-val">{this.state.reviewDetails?.lastName}</Text></div>
                                </div>
                           }
                                {this.state.reviewDetails?.bankName &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">Bank Name </div>
                                    <div>  <Text className="kpi-val">{this.state?.reviewDetails?.bankName || "-"}</Text></div>
                                </div>
                            }
                            {this.state.reviewDetails?.iban &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">IBAN </div>
                                    <div> <Text className="kpi-val">{this.state.reviewDetails?.iban}</Text></div>
                                </div>
                           }
                              
                             {this.state.reviewDetails?.abaRoutingCode &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">ABA Routing code</div>
                                    <div> <Text className="kpi-val">{this.state.reviewDetails?.abaRoutingCode || "-"}</Text></div>
                                </div>
                                  }
                {this.state.reviewDetails?.swiftRouteBICNumber &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">{"SWIFT / BIC Code"}</div>
                                    <div>  <Text className="kpi-val">{this.state.reviewDetails?.swiftRouteBICNumber || "-"}</Text></div>
                                </div>
                            }
                              {(this.state.reviewDetails?.ukSortCode ||this.props.selectedCurrency == "GBP") &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">{ "Uk Sort Code"}</div>
                                    <div>  <Text className="kpi-val">{this.state.reviewDetails?.ukSortCode || "-"}</Text></div>
                                </div>
                            }
                {this.state.reviewDetails?.accountNumber &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">Account Number </div>
                                    <div>  <Text className="kpi-val">{this.state.reviewDetails?.accountNumber || "-"}</Text></div>
                                </div>
                           }
                           {this.state.reviewDetails?.customerRemarks &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">Reason For Transfer </div>
                                    <div>  <Text className="kpi-val">{this.state.reviewDetails?.customerRemarks || "-"} {" "} 
                                    {this.state.reviewDetails?.customerRemarks === "Others" && `(${this.state.reviewDetails?.transferOthers})`}</Text></div>
                                </div>
                          }
                           {this.state.reviewDetails?.ukShortCode &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">Uk Sort Code</div>
                                    <div>  <Text className="kpi-val">{this.state.reviewDetails?.ukShortCode || "-"}</Text></div>
                                </div>
                           }
              
                           
                           {this.state.reviewDetails?.phoneNumber &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">Phone Number </div>
                                    <div>  <Text className="kpi-val">{this.state?.reviewDetails?.phoneNumber || "-"}</Text></div>
                                </div>
                            }
                             {this.state.reviewDetails?.email &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">Email</div>
                                    <div>  <Text className="kpi-val">{this.state?.reviewDetails?.email || "-"}</Text></div>
                                </div>
                            }
                             {this.state.reviewDetails?.country &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">Country</div>
                                    <div>  <Text className="kpi-val">{this.state?.reviewDetails?.country || "-"}</Text></div>
                                </div>
                            }
                             {this.state.reviewDetails?.state &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">State</div>
                                    <div>  <Text className="kpi-val">{this.state?.reviewDetails?.state || "-"}</Text></div>
                                </div>
                            }
                             {this.state.reviewDetails?.city &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">City</div>
                                    <div>  <Text className="kpi-val">{this.state?.reviewDetails?.city || "-"}</Text></div>
                                </div>
                            }
                             {this.state.reviewDetails?.zipcode &&
                                <div className="kpi-divstyle" >
                                    <div className="kpi-label">Zip Code </div>
                                    <div>  <Text className="kpi-val">{this.state?.reviewDetails?.zipcode || "-"}</Text></div>
                                </div>
                            }
                           
                            </div>
                            <Form
                        autoComplete="off"
                        name="advanced_search"
                        ref={this.reviewForm}
                        onFinish={this.saveWithdrawdata}
                        scrollToFirstError={{
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'center',
                          }}
                        >
                             <Row gutter={24} className=" text-white mt-36">
                           {this.props.match?.params?.isBank.toLowerCase() !== "openpayd" &&<> {!this.state.reviewDetails?.phoneNumber && (
                            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                            <Form.Item
                                className="custom-forminput custom-label mb-0"
                                name="phoneNo"
                                label={"Recipient Phone Number"}
                                required
                                rules={[
                                    {
                                        validator:(_,Val)=>{
                                            if (!Val) {
                                                return Promise.reject("Is required");
                                            } else if (!(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(Val)) || /[<>!@#$%^&*()_+[\]{};':"\\|,.<>?]/.test(Val) || Val.includes(".")) {
                                        
                                                return Promise.reject("Invalid phone number");
                                            } else if(!this.state.phoneCode){
                                                return Promise.reject("Select country code");
                                            }else {
                                                return validateContentRule(_, Val);
                                            }
                                        }
                                    }, 
                                ]}
                            >
                                 <NumberFormat
                                        customInput={Input}
                                        className="cust-input cust-phnAddon cust-input cust-select-arrow tfunds-inputgroup"
                                        placeholder={"Recipient Phone"}
                                        maxLength={12}
                                        decimalScale={false}
                                        displayType="input"
                                        allowNegative={false}
                                        thousandSeparator={false}
                                        addonBefore={<Select className='text-white' style={{width: "175px"}}
                                    onChange={(e)=>{
                                        this.reviewForm.current.validateFields(["phoneNo"])
                                        this.setState({...this.state,phoneCode:e})}}
                                    showSearch
                                    placeholder="Country Code" >
                                        {PhoneCodes?.countries?.map((code,index)=>{
                                            return <Option key={index} className='text-white' value={code.code}>{code.code}</Option>
                                        })}
                                    </Select>}
                                />
                            </Form.Item>
                            </Col>)}
                            {!this.state.reviewDetails?.email && (
                            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                            <Form.Item
                                className="custom-forminput custom-label mb-0 mt-16"
                                name="email"
                                label={"Recipient Email"}
                                required
                                rules={[
                                    
                                        {
                                            validator:(_,Val)=>{
                                                if (!Val) {
                                                    return Promise.reject("Is required");
                                                } else if (Val.length < 5 || Val.length > 50) {
                                            
                                                    return Promise.reject("Invalid Email");
                                                
                                                } else if (!(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,100}$/.test(Val))) {
                                                    return Promise.reject("Invalid Email");
                                                } else {
                                                    return validateContentRule(_, Val);
                                                }
                                            }
                                        }
                                    
                                ]}
                            >
                                <Input
                                    className="cust-input "
                                    placeholder={"Recipient Email"}
                                    maxLength="500"
                                />
                            </Form.Item>
                            </Col>)}
                            {!this.state.reviewDetails?.country && (
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          name="country"
                          label="Country"
                          className="custom-forminput custom-label mb-0 mt-16"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Is required"
                            },
                            {
                              whitespace: true,
                              message: "Is required"
                            },
                            
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Country"
                            className="cust-input select-crypto cust-adon mb-0  c-pointer"
                            dropdownClassName="select-drpdwn"
                            onChange={(e) => this.handleCountry(e)}
                            bordered={false}
                         

                          >
                            {this.state.country?.map((item, index) => (
                              <Option key={index} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                            </Col>)}</>}
                    {(this.state.selectedBankLuData?.bankName || this.props.match?.params?.isBank).toLowerCase() !== "openpayd" &&<> {!this.state.reviewDetails?.state && (
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          name= "state"
                          label="State"
                          required
                          className="custom-forminput custom-label mb-0 mt-16"
                          rules={[
                            {
                              required: true,
                              message: "Is required"
                            },
                            {
                              whitespace: true,
                              message: "Is required"
                            },
                            
                          ]}
                        >
                          <Select
                            showSearch
                            // dropdownClassName="select-drpdwn"
                            className="cust-input select-crypto cust-adon mb-0 c-pointer custom-label"
                            placeholder="State"
                            onChange={(e) => this.handleChange(e)}

                          >
                            {this.state.state?.map((item, index) => (
                              <Option key={index} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>)}
                      {!this.state.reviewDetails?.city && (
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          className="custom-forminput custom-label mb-0 mt-16"
                          name="city"
                          required
                          
                          rules={[
                            {
                                required: true,
                                message: "Is required"
                              },
                              {
                                whitespace: true,
                                message: "Is required"
                              },
                            {
                                validator: validateContentRule,
                            },
                        ]}
                          label={"City"}
                        >
                          <Input className="cust-input" placeholder={"City"}
                          maxLength={100}

                          />
                        </Form.Item>
                      </Col>)}
                     {!this.state.reviewDetails?.zipcode && (
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          className="custom-forminput custom-label mb-0 mt-16"
                          name="postalCode"                         
                          rules={[
                            {  whitespace: true,required: true, message: "Is required", },
                            {
                                validator: (_, value) => {
                                    if (value && value.match(/\./g)) {
                                        return Promise.reject("Only numbers allowed");
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                          label={"Zip Code"}
                        >
                                     <NumberFormat
                                        customInput={Input}
                                        className="cust-input cust-select-arrow tfunds-inputgroup"
                                        placeholder={"Zip Code"}
                                        maxLength="6"
                                        displayType="input"
                                        allowNegative={false}
                                    />
                        </Form.Item>
                      </Col>)}</>}
                      </Row>
                      <Row className=" text-white" style={{width: '100%'}}>
                            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                                <Verifications onchangeData={(obj) => this.changesVerification(obj)} onReviewDetailsLoading={(val) => this.onReviewDetailsLoading(val)} onClosePopup={()=>this.props?.onClosePopup()} verificationsData={(data)=>this.verificationsData(data)}/>
                            </Col>
                           
                            {this.state.permissions?.Send  && (
                            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                                <div className="create-account">
                                    <Form.Item className="mb-0 mt-16">
                                    <Button
                                            htmlType="submit"
                                            size="large"
                                            block
                                            className="pop-btn custom-send cust-disabled"
                                            style={{ backgroundColor: !isShowGreyButton && '#7087FF', borderColor: !isShowGreyButton && '#7087FF' }}
                                            loading={this.state.isBtnLoading} 
                                            disabled={!isShowGreyButton}
                                            >
                                            Confirm & Continue
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Col>)}
                        </Row>
                    </Form>
            </React.Fragment>,
            newtransfer: <>
                <FiatAddress currency={this.state.selectedCurrency} selectedBank={this.props?.match?.params?.isBank} selectedAccountId={this.state.selectedAccountId} amount={this.state.amount} onContinue={(obj) => {
                 
                    this.setState({ ...this.state,selectedPayee:obj,selectCurrencyLabelShow:true }, () => {
                        this.chnageStep("addressselection")
                    })
                }
                }
                    onAddressOptionsChange={(value) => this.setState({ ...this.state, addressOptions: value })} onTheGoObj={this.state.onTheGoObj} reasonAddress={this.addressDoc}/>
            </>,
            declaration:<div className="custom-declaraton send-success">
          {process.env.REACT_APP_ISDOCUSIGN == "false" && <div className="success-pop text-center declaration-content">
              <Image preview={false} src={alertIcon} className="confirm-icon" />
              <Title level={2} className="success-title">Declaration form sent successfully</Title>
              <Text className="successsubtext">{`Declaration form has been sent to ${this.props.userProfile?.email}. 
               Please sign using link received in email to whitelist your address. Please note that any transactions regarding this whitelist will only be processed once your whitelisted address has been approved.`}</Text>
             
            </div> || <div className="success-pop text-center declaration-content">
       <Image  preview={false} src={success}  className="confirm-icon" />
       <Title level={2} className="success-title">Address saved successfully</Title>
       {/* <p className="text-white">Please note that any transactions regarding this whitelist will only be processed once your whitelisted address has been approved</p> */}
   </div>}
          </div>,
            successpage: <div className="custom-declaraton send-success"> <div className="success-pop text-center declaration-content">
            <Image  preview={false} src={success}  className="confirm-icon" />
            <Title level={3} className="cust-heightmbl sucess-subtitle">Your transaction has been submitted successfully</Title>
            <Button content="Back_to_Withdrawfiat" className=" cust-cancel-btn" size="large" 
             onClick={() => { this.handleClose() }}  
            >Back to Dashboard</Button>
        </div></div>
        }
        return steps[this.state.step];
    }
    render() {
        return <React.Fragment>
            
            <Drawer
                    destroyOnClose={true}
                    title={[ <span onClick={this.handleClose} className="icon md close-white c-pointer" />]}
                    className="side-drawer w-50p"
                    visible={this.state.showFuntransfer}
                >
                    <div ref={this.mainref}>
                    <div className="side-drawer-header"><span></span>   
                        {!["reviewdetails","declaration","successpage"].includes(this.state.step?.toLowerCase()) &&
                            <Paragraph className="drawer-maintitle send-space">Send
                            </Paragraph>
                        }

                    </div>
                    </div>
                    {this.renderStep()}
                </Drawer>
                <Modal
  title="Download Confirmation Slip"
  visible={this.state.downloadPopup}
  closeIcon={
    <Tooltip title="Close">
      <span
        className="icon md close-white c-pointer"
        onClick={() => this.handledwnClose()}
      />
    </Tooltip>
  }
  footer={null} >
  
  {this?.state.popupLoader ? <Loader /> :
    <Row gutter={24}>
    { this.state.isDownLoadShow && <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
        <div className="d-flex justify-content align-center" >
          <Text className="mb-8 fs-14 text-white fw-500">Download as PDF </Text>
          <a className="icon md download-arrow text-right c-pointer" download="Confirmation slip" href={"data:application/pdf;base64," + this.state.fileData} title='Download pdf document' />
        </div>
      </Col>}
    </Row>}
</Modal>
        </React.Fragment>
    }
}
const ConnectStateProps = ({ sendReceive, userConfig, menuItems, auth }) => {
    return {
      sendReceive,
      userProfile: userConfig?.userProfileInfo,
      sendPermissions: menuItems?.featurePermissions?.send,
      auth: auth?.user?.profile
    };
  };
  const connectDispatchToProps = dispatch => {
    return {
      getBankDetails: (id) => {
        dispatch(getBankData(id));
      }
    }
  }
export default connect(ConnectStateProps,connectDispatchToProps)(withRouter(OnthegoFundTransfer));