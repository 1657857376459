import React, { Component } from "react";

import Typography from "antd/lib/typography";
import Drawer from "antd/lib/drawer";

import { connect } from "react-redux";
import { receiveSteps as config } from './config';
import ReceiveFund from "./receiveFunds.component";
import setStep from '../../reducers/receiveReducer'
import SelectCurrency from "./selectCurrency";

const {  Paragraph } = Typography;

class Receive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDrawer: false,

        };
    }
    gridRef = React.createRef();
    formRef = React.createRef();
    componentDidMount() {
        this.setState({ ...this.state, showDrawer: true })
    }
    addTransferDrawer = () => {
        this.setState({ ...this.state, showDrawer: true })
    }
    closeTransferDrawer = () => {
        let _path = this?.props?.location?.pathname?.split("/");
        if (_path && _path[2] === 'receive') {
            this.props?.history?.push('/dashboard');
        }
        else {
            this.setState({ ...this.state, showDrawer: false })
            this.props.onreciveClose();
        }


    }
    renderContent = () => {
        const stepcodes = {
            step1:<SelectCurrency currency={this.props?.currency} reciveId={this.props?.reciveId} {...this.props}/>,
            receiveFunds:<ReceiveFund reciveCurrency={this.props?.currency} reciveId={this.props?.reciveId} {...this.props} />
        }
        return stepcodes[config[this.props.receiveData.stepcode]]
    }
    renderTitle = () => {
        const titles = {
            receiveFunds: <span />,
        }
        return titles[config[this.props.receiveData.stepcode]]
    }
    renderIcon = () => {
        const stepcodes = {
            step1: <span onClick={this.closeTransferDrawer} className="icon md close-white c-pointer" />,
            receiveFunds: <span onClick={this.closeTransferDrawer} className="icon md close-white c-pointer" />,
        }
        return stepcodes[config[this.props.receiveData.stepcode]]
    }

    render() {

        return (
            <>
                <Drawer
                    destroyOnClose={true}
                    title={[<div className="side-drawer-header">

                        {this.renderIcon()}
                    </div>]}
                    className="side-drawer w-50p"
                    visible={this.state.showDrawer}
                >
                     {this.renderTitle()}

                        <div className="text-center fs-24">
                            <Paragraph className="drawer-maintitle">{this.props.receiveData.stepTitles[config[this.props.receiveData.stepcode]]}</Paragraph>

                        </div>
                    {this.renderContent()}
                </Drawer>
            </>
        );
    }
}
const connectStateToProps = ({ receiveData }) => {
    return {
        receiveData

    };
};
const connectDispatchToProps = dispatch => {
    return {
        changeStep: (stepcode) => {
            dispatch(setStep(stepcode))
        }
    }
}
export default connect(connectStateToProps,connectDispatchToProps)(Receive);
