import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import  message  from "antd/lib/message";
import Alert from "antd/lib/alert";
import DatePicker from "antd/lib/date-picker";
import { saveAccount, getCustomerData,getAddressTypeLU ,getCountryStateLu,isErrorDispaly} from "../../api/apiCalls";
import { connect } from "react-redux";
import Loader from "../shared/loader";
import moment from "moment";
import {validateContentRule,validateFeild} from '../../utils/custom.validator'
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";

const { Title, Text } = Typography;
const { Option } = Select;

const CreateAccount = (props) => {
  const [form] = Form.useForm();
  const [error, setError] = useState(null);
  const [accountloading, setAccountloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [country, setCountry] = useState([]);
  const [states,setStates]=useState([]);
  const [addressType,setAddressType] = useState([])
  let history = useHistory();
  useEffect(() => {
    getData();
    setCustomerData(props.customerData);
    getAddressDropDownLU()
   }, []);

  const getData = async () => {
    setIsLoading(true);
    let response = await getCustomerData();
    if (response.ok) {
      setError(null)
      setCustomerData(response.data);
      getCountryLU(response.data?.address?.country);
      setIsLoading(false);
    } else {
      setError(isErrorDispaly(response));
      setIsLoading(false);
    }
  };

  const savedetails = async (values) => {
    setAccountloading(true);
    let objs = {
      ...customerData,
      firstName: customerData.firstName,
      lastName: customerData.lastName,
      phone: customerData.phone,  
      dateOfBirth: customerData.dateOfBirth || values.dateOfBirth,
      email: customerData.email,
      idNumber: values.idNumber,
      idType: values.idType,
      currency: props.currency,
      address: { ...customerData.address, ...values.address },
      IsBusiness: props.userConfig.isBusiness,
      customerId: props.userConfig.id,
      UserName: props.userConfig.userName,
      OneTimeFee:props?.FeesData?.account ? props?.FeesData?.account["Monthly Fee"] : null,
      MonthlyFee:props?.FeesData?.account ? props?.FeesData?.account["One Time Fee"] : null,
      bankProvider:props?.isbank
    };

    const response = await saveAccount(objs);
    if (response.ok) {
      setError(null)
      setAccountloading(false);
      message.destroy();

      message.success({
        content: "Account created successfully",
        className: "custom-msg",
        duration: 4
      });
      history.push(`/progressPage/${props?.currency}/${props?.isbank?.toLowerCase()}`);
    } else {
      setError(isErrorDispaly(response));
      setAccountloading(false);
      window.scrollTo(0, 0);
      form.resetFields();
    }
  };
  function disabledDate(current) {
   
    const weekEnd = moment().endOf("day");
    return !weekEnd.isAfter(current);
   
  }
  const getCountryLU = async (countryName) => {
    let res = await getCountryStateLu();
    if (res.ok) {
      setCountry(res.data);
      setError(null)
      if (countryName) {
        let filteredCountry = res.data.filter((countrys) => {
            return countrys.code && countryName && countrys?.code?.toLowerCase() == countryName?.toLowerCase();
        });
        if (filteredCountry.length > 0) {
          setStates(filteredCountry[0].stateLookUp);
        }
      }
    }else{
      setError(isErrorDispaly(res))
    }
  };
  const handleCountry = (state, stateLuc) => {
    form.setFieldsValue({ state: " " });
    setStates([]);
    let selectCountry = stateLuc ? stateLuc : country;
    selectCountry?.map((item) => {
      if (state === item.code) {
        setStates(item?.stateLookUp);
      }
    });
  };
  const handleChange = (code, stateLuc) => {
    let selectCountry = stateLuc ? stateLuc : country;
    if (code?.toLowerCase() === selectCountry.code?.toLowerCase()) {
      setStates(country.stateLookUp);
     
    }
  };

   const getAddressDropDownLU= async ()=>{
    let response = await getAddressTypeLU()
    if(response.ok){
      setAddressType(response.data)
      setError(null)
    }else{
      setError(isErrorDispaly(response));
    }
   }
  return (
    <>
     
      {isLoading ? (
        <Loader />
      ) : (
        <Form form={form} onFinish={savedetails} scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        }} autoComplete="off" initialValues={customerData}>
          <div className="creat-bg">
           
           
            <div className="custom-createaccount cust-bg-remove">
            {error != undefined && error != null && (
        <Alert
          className="w-100 mb-16"
          type="error"
          showIcon
          description={error}
        />
      )}
            <div className="cust-scroll">
              <Title className="db-titles">
              Review Your Details
              </Title>
            </div>
            
              <div className="box basic-info alert-info-custom">
              <Title className="adbook-head review-head">
              Personal Details{" "}
              </Title>
                <Row gutter={24} className="pb-8">
                  <Col xs={24} sm={24} md={12} className="mb-16">
                    <label className="case-lbl">
                      First Name 
                    </label>
                    <div className="">
                      <Text className="case-val">
                        {props.userConfig.isBusiness
                          ? props.userConfig.businessName
                          : props.userConfig.firstName}
                      </Text>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} className="mb-16">
                    <label className="case-lbl">
                      Last Name 
                    </label>
                    <div className="">
                      <Text className="case-val">
                        {props.userConfig.lastName || "--"}
                      </Text>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} className="mb-16">
                    <label className="case-lbl">Phone </label>
                    <div className="">
                      <Text className="case-val">
                        {props.userConfig.phoneNo || "--"}
                      </Text>{" "}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} className="mb-16">
                    <label className="case-lbl">Email </label>
                    <div className="">
                      <Text className="case-val">
                        {props.userConfig.email || "--"}
                      </Text>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} className="mb-16">
                    <label className="case-lbl">
                      Currency {" "}
                    </label>
                    <div className="">
                      <Text className="case-val">                        
                        {props.currency || "--"}
                      </Text>
                    </div>
                  </Col>
                  {customerData.dateOfBirth  && (
                    <Col xs={24} sm={24} md={12} className="mb-16">
                    <label className="case-lbl">Date Of Birth </label>
                    <div className="">
                      <Text className="fs-14 fw-600 text-white">
                        {moment(customerData.dateOfBirth).format("DD-MM-YYYY" )}
                      </Text>
                    </div>
                  </Col>
                  )}
                </Row>
                {!customerData.dateOfBirth &&
               
               ( <Row gutter={24} className="pb-8">
                  <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                      className="input-label custom-label mb-0 c-pointer autofil-bg"
                      name="dateOfBirth"
                      rules={[
                        {
                          required: true,
                          message: "Is required"
                        }
                      ]}
                      label={"Date Of Birth"}
                    >
                      <DatePicker
                        format={"DD/MM/YYYY"}
                        className="cust-input"
                        placeholder="Select Date"
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                </Row>)
               
              }
              </div>
                  {props?.isbank?.toLowerCase() !== "openpayd" && <div className="box basic-info alert-info-custom">
                  <Title
                    className="adbook-head review-head"
                  >
                    Identification Details{" "}
                  </Title>
                    <Row gutter={24} className="pb-8">
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          className="custom-forminput custom-label mb-0"
                          name="idNumber"
                          required
                          rules={[
                            {
                              whitespace: true,
                              message: "Is required"
                            },
                            {
                              validator:(_,val)=>{
                                  return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'id number'})
                              }
                          },
                          ]}
                          label={"Passport Number"}
                        >
                          <Input
                            className="cust-input"
                            placeholder={"Passport Number"}
                            disabled={customerData.idNumber ? true :false}
                          />
                        </Form.Item>
                      </Col>                     
                    </Row>
                  </div>}
                  <div className="box basic-info alert-info-custom">
                  <Title
                    className="adbook-head review-head"
                  >
                    Address Details{" "}
                  </Title>
                    <Row gutter={24} className="pb-8">
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                            name={["address", "addressType"]}
                            label="Address Type"
                            className="custom-forminput custom-label mb-0 mt-16"
                            required
                            rules={[
                              {
                                  required: true,
                                  message: "Is required",
                              },
                              {
                                  whitespace: true,
                                  message: "Is required"
                              },
                              {
                              validator:validateContentRule,
                              },
                          ]}                         
                              >
                             <Select
                             placeholder="Select Address Type"
                           className="cust-input"
                           dropdownClassName="select-drpdwn"
                           disabled={customerData.address?.addressType ? true :false}
                             >                           
                           {addressType.map((item,index)=>(
                             <Option key={index} value={item.name}>  
                             {item.name}                         
                              </Option>))}
                          </Select>
                        </Form.Item>
                    </Col>
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          name={["address", "line1"]}
                          label="Address"
                            className="custom-forminput custom-label mb-0 mt-16"
                            rules={[
                              {
                                required: true,
                                message: "Is required",
                            },
                              {
                                whitespace: true,
                                message: "Is required"
                              },
                              {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,maxLength:1000,validateContentRule:true,lableName:'address'})
                                }
                            },
                            ]}
                        >
                            <Input placeholder="Address" 
                            className="cust-input"
                            disabled={customerData.address?.line1 ? true :false}
                            maxLength={1000}
                            />
                        </Form.Item>
                    </Col>
                     
                    
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          name={["address", "country"]}
                          label="Country"
                          className="custom-forminput custom-label mb-0 mt-16"
                          rules={[
                            {
                              required: true,
                              message: "Is required"
                            },
                            {
                              whitespace: true,
                              message: "Is required"
                            },
                            
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Country"
                            className="cust-input select-crypto cust-adon mb-0 text-left c-pointer"
                            dropdownClassName="select-drpdwn"
                            onChange={(e) => handleCountry(e)}
                            bordered={false}
                            disabled={customerData.address?.country ? true :false}
                         

                          >
                            {country?.map((item, index) => (
                              <Option key={index} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          name={["address", "state"]}
                          label="State"
                          className="custom-forminput custom-label mb-0 mt-16"
                          rules={[
                            {
                              required: true,
                              message: "Is required"
                            },
                            {
                              whitespace: true,
                              message: "Is required"
                            },
                            
                          ]}
                        >
                          <Select
                            showSearch
                            dropdownClassName="select-drpdwn"
                            className="cust-input select-crypto cust-adon mb-0 c-pointer custom-label"
                            placeholder="Select State"
                            onChange={(e) => handleChange(e)}
                            disabled={customerData.address?.state ? true :false}

                          >
                            {states?.map((item, index) => (
                              <Option key={index} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                           name={["address", "city"]}
                          label="City"
                            className="custom-forminput custom-label mb-0 mt-16"
                            required
                            rules={[
                              {
                                whitespace: true,
                                message: "Is required"
                              },
                              {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'city'})
                                }
                            },
                            ]}
                        >
                            <Input placeholder="City" 
                            className="cust-input" 
                            disabled={customerData.address?.city ? true :false}
                            maxLength={50}
                            />
                        </Form.Item>
                    </Col>
                      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          className="custom-forminput custom-label mb-0 mt-16"
                          name={["address", "postalCode"]}
                          required
                          rules={[
                            { required: true, message: "Is required", },
                            {
                              validator: (_, value) => {
                                  if (value && value.match(/\./g)) {
                                      return Promise.reject("Only numbers allowed");
                                  }
                                  return Promise.resolve();
                              }
                          }
                        ]}
                          label={"Postal Code"}
                        >
                         <NumberFormat
                                        customInput={Input}
                                        className="cust-input cust-select-arrow tfunds-inputgroup"
                                        placeholder={"Postal Code"}
                                        maxLength="6"
                                        displayType="input"
                                        allowNegative={false}
                                        disabled={customerData.address?.postalCode}
                                    />

                        </Form.Item>
                      </Col>
                      
                   
                    </Row>
                  </div>
              <div className="text-right mt-24 pb-8 create-account">
                <Form.Item>
                  {" "}
                  <Button
                  block
                    loading={accountloading}
                    type="submit"
                    htmlType="submit"
                    size="large"
                    className="pop-btn px-24"
                  >
                    Confirm & Continue
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      )}
    </>
  );
};
const connectStateToProps = ({ userConfig, customerDetails }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    customerDetails: customerDetails.data
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};
export default connect(
  connectStateToProps,
  connectDispatchToProps
)(CreateAccount);
