import Layout from 'antd/lib/layout';
import RouteConfig from './routes';
import { useSelector } from 'react-redux';
const { Content } = Layout;
const AppContent = () => {
    const fetures = useSelector((state)=>state.menuItems.features.originalData)
    return (<Content className="sidebar-push">
        <div className="main-container db-container">
        {fetures?.length>0 && <RouteConfig />}
        {(!fetures || fetures?.length<1) && <div className="loader">Loading .....</div>}
        </div>
    </Content>)
}

export default AppContent;