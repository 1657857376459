import {useEffect } from "react";
import {useAuth0} from '@auth0/auth0-react';
const Wraplogout = (props) => {
    const {logout} = useAuth0();

    useEffect(()=>{
        if(props.isLogout){
            logoutuser()
        }
    },[props.isLogout])
    const logoutuser = () =>{
        logout();
    }
    return(<></>)
};

export default Wraplogout;