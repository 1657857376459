import React from "react"
import internal from '../../assets/images/internal.png'
import Typography from "antd/lib/typography";

const { Text } = Typography;

const CommoingInternalTransfer = () => {

    return <div className="text-center intertrans">
        <div className="">
        <img src={internal} className="confirm-icon" style={{ marginBottom: '10px' }} alt="Confirm" />
        <div><Text className="text-center fw-700 fs-40 textpure-yellow">Coming soon</Text></div>
        </div>

        </div>

}
export default CommoingInternalTransfer;