import React, { Component } from 'react'
import { Button,Modal } from 'antd';
import App from '../App';
import IdleTimer from 'react-idle-timer'
import Wraplogout from '../auth/logout-component'
class IdleCmp extends Component {
    _count = 15;
    timeInterval;
    state = {
        counter: 15,
        showIdleModal: false
    }
    handleOnIdle = () => {
        this.setState({ ...this.state, showIdleModal: true,logoutuser:true });
        this.timeInterval = setInterval(() => {
            if (this._count === 0) {
                 clearInterval(this.timeInterval);
                } else {
                this._count = this._count - 1;
                this.setState({ ...this.state, counter: this._count });
            }
        }, 1000);
    }


    handleCancel = () => {
        clearInterval(this.timeInterval); this._count = 15;
        this.setState({ ...this.state, counter: this._count, showIdleModal: false });
    }
    handleOnActive = () => {
    }

    render() {
        const { showIdleModal, counter } = this.state;
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 15}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
                <Wraplogout isLogout={this.state.logoutuser} />
                <App />
                <Modal
                    title="Session timedout alert" visible={showIdleModal}
                    closable={false}
                    closeIcon={false}
                    footer={[
                        <>
                            <Button style={{ width: 100 }}
                                className="cust-cancel-btn"
                                onClick={() => this.handleCancel()}>Cancel</Button>
                            <Button className="primary-btn pop-btn"
                                style={{ width: 100, height: 50 }}
                                onClick={() => {this.setState({ ...this.state, logoutuser:true }); }}>Ok</Button>
                        </>
                    ]} >
                    <h4 className="text-white fs-16 fw-400">You're session will be logged out in {counter}</h4>
                </Modal>
            </div >
        )
    }
}

export default IdleCmp