import { bankClient, apiMain } from "../../api/clients"
import { ApiControllers } from '../../api/config';

const getCurrency = (currency) => {
    return bankClient.get(ApiControllers.bank + `GetAccountBalance/${currency}`)
}
const getListOfCurrencies = () => {
    return bankClient.get(ApiControllers.bank + `ListofCurrencies`)
}
const requestDepositFiat = (bankId) => {
    return apiMain.get(ApiControllers.deposit + `Deposit/Fiat/`  + '/' + bankId)
}
const getReceiveViewData = (accountId) => {
    return bankClient.get(
        ApiControllers.bank + `ReceiveFunds/${accountId}`);
}
export { getCurrency, getListOfCurrencies, requestDepositFiat, getReceiveViewData }
